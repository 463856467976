<template>
  <div class="min-h-screen bg-gray-100">
    <MainLayout :sports="sports">
      <div class="bg-custom-black py-8 px-4">
        <div class="w-full max-w-md mx-auto">
          <h2 class="text-2xl font-bold text-white mb-6 text-center">
            {{ t('withdrawal.requestWithdrawal') }}
          </h2>
          <form @submit.prevent="handleSubmit" class="space-y-4" autocomplete="off">
            <!-- Current Balance -->
            <div class="bg-white rounded-lg overflow-hidden p-4">
              <h3 class="text-lg font-semibold text-gray-800 mb-2">{{ t('withdrawal.currentBalance') }}</h3>
              <div v-for="wallet in currentUserWallets" :key="wallet.id" class="flex justify-between items-center">
                <span>{{ wallet.currency.name }} ({{ wallet.currency.code }})</span>
                <span class="font-bold">{{ formatCurrency(wallet.balance, wallet.currency.code) }}</span>
              </div>
            </div>

            <!-- Withdrawal Amount -->
            <div class="bg-white rounded-lg overflow-hidden">
              <input
                v-model="withdrawalAmount"
                type="number"
                step="0.01"
                :placeholder="t('withdrawal.amount')"
                class="w-full p-4 text-bg-custom-black placeholder-gray-500 focus:outline-none"
                required
              />
              <p v-if="errors.amount" class="text-red-500 text-sm mt-1 px-4">{{ errors.amount }}</p>
            </div>

            <!-- Currency Dropdown -->
            <div class="relative bg-white rounded-lg overflow-hidden">
              <select
                v-model="selectedWallet"
                class="w-full p-4 text-bg-custom-black appearance-none focus:outline-none"
                required
              >
                <option
                  v-for="wallet in currentUserWallets"
                  :key="wallet.id"
                  :value="wallet.id"
                >
                  {{ wallet.currency.name }} ({{ wallet.currency.code }})
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>
              <p v-if="errors.wallet" class="text-red-500 text-sm mt-1 px-4">{{ errors.wallet }}</p>
            </div>

            <!-- Submit Button -->
            <button
              type="submit"
              class="w-full bg-custom-bg-green text-white p-4 rounded-lg hover:bg-custom-green transition-colors duration-200"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? t('withdrawal.processing') : t('withdrawal.submit') }}
            </button>
          </form>
        </div>
      </div>
    </MainLayout>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import MainLayout from '../layouts/MainLayout.vue'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const withdrawalAmount = ref('')
const selectedWallet = ref('')
const errors = ref({})
const isSubmitting = ref(false)

const sports = computed(() => store.state.sports || [])
const currentUser = computed(() => store.getters.user || {})
const currentUserWallets = computed(() => currentUser.value.wallets || [])

const formatCurrency = (amount, currency) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(amount)
}

const validateForm = () => {
  errors.value = {}
  if (!withdrawalAmount.value || parseFloat(withdrawalAmount.value) <= 0) {
    errors.value.amount = t('withdrawal.errors.invalidAmount')
  }
  if (!selectedWallet.value) {
    errors.value.wallet = t('withdrawal.errors.walletRequired')
  }
  return Object.keys(errors.value).length === 0
}

const handleSubmit = async () => {
  if (!validateForm()) {
    return
  }
  try {
    isSubmitting.value = true
    const payload = {
      amount: parseFloat(withdrawalAmount.value),
      wallet_id: selectedWallet.value
    }

    console.log('payload:', payload)

    const authData = JSON.parse(localStorage.getItem('forbet_auth') || '{}')
    const token = authData.accessToken

    console.log('token:', token)

    if (!token) {
      throw new Error(t('withdrawal.errors.authTokenNotFound'))
    }

    const response = await fetch('https://api.newbet365.org/api/fiat-withdrawals', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    })

    if (!response.ok) {
      const errorData = await response.json()
      throw new Error(errorData.message || t('withdrawal.errors.failedToSubmitRequest'))
    }

    const data = await response.json()
    console.log(t('withdrawal.requestSubmittedSuccessfully'), data)

    // Reset form and redirect
    withdrawalAmount.value = ''
    selectedWallet.value = ''
    await store.dispatch("fetchUser")
    router.push('/withdrawals')
  } catch (error) {
    console.error('Withdrawal request error:', error)
    errors.value.general = error.message || t('withdrawal.errors.failedToSubmitRequest')
  } finally {
    isSubmitting.value = false
  }
}
</script>

<style scoped>
.withdrawal-request-view {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

label {
  font-weight: bold;
}

input, select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px;
  background-color: 'bg-custom-bg-green';
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  margin-top: 5px;
}
</style>

