import { createStore } from "vuex";
import { betslip } from './modules/betslip'
import * as auth from './modules/auth'

const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const loadFromLocalStorage = (key) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const shouldFetchData = (key) => {
  const cachedData = loadFromLocalStorage(key);
  if (!cachedData) return true;
  return Date.now() - cachedData.timestamp > CACHE_DURATION;
};

export default createStore({

  state: {
    upcomingMatches: [],
    sports: [],
    todaysMatches: [],
    featuredLeague: null,
    countries: [],
    leagues: [],
    isLoading: false,
    currentSportId: 1,
    currentLiveSportId: 1,
    currentCountryId: 1,
    selectedMatch: null,
    selectedLeague: null,
    upcomingLeagues: [],
    liveLeagues: [],
    liveSports: [],
    liveEventsCounts: {},
    isLoginModalOpen: false,
    language: 'en',
    currentLanguage: 'en'
  },
  getters: {
    upcomingMatches: (state) => state.upcomingMatches,
    sports: (state) => state.sports,
    todaysMatches: (state) => state.todaysMatches,
    featuredLeague: (state) => state.featuredLeague,
    countries: (state) => state.countries,
    leagues: (state) => state.leagues,
    isLoading: (state) => state.isLoading,
    currentSportId: (state) => state.currentSportId,
    currentLiveSportId: (state) => state.currentLiveSportId,
    currentCountryId: (state) => state.currentCountryId,
    hasLeaguesData: (state) => state.leagues && Array.isArray(state.leagues.data) && state.leagues.data.length > 0,
    selectedMatch: (state) => state.selectedMatch,
    selectedLeague: (state) => state.selectedLeague,
    upcomingLeagues: (state) => state.upcomingLeagues,
    liveLeagues: (state) => state.liveLeagues,
    liveSports: (state) => state.liveSports,
    getLiveEventsCount: (state) => (sportId) => {
      const count = state.liveEventsCounts[sportId];
      // console.log(`Getting count for sport ${sportId}:`, count);
      return count || 0;
    },
    currentLanguage: state => state.currentLanguage
  },
  mutations: {
    SET_UPCOMING_MATCHES(state, matches) {
      state.upcomingMatches = matches;
    },
    SET_LOADING(state, loading) {
      state.isLoading = loading;
    },
    SET_SPORTS(state, sports) {
      state.sports = sports;
    },
    SET_TODAYS_MATCHES(state, matches) {
      state.todaysMatches = matches;
    },
    SET_FEATURED_LEAGUE(state, league) {
      state.featuredLeague = league;
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    SET_CURRENT_SPORT_ID(state, id) {
      state.currentSportId = id;
    },
    SET_CURRENT_LIVE_SPORT_ID(state, id) {
      state.currentLiveSportId = id;
    },
    SET_CURRENT_COUNTRY_ID(state, id) {
      state.currentCountryId = id;
    },
    SET_LEAGUES(state, leagues) {
      state.leagues = leagues;
    },
    SET_SELECTED_MATCH(state, match) {
      state.selectedMatch = match;
    },
    SET_SELECTED_LEAGUE(state, league) {
      state.selectedLeague = league;
    },
    SET_UPCOMING_LEAGUES(state, leagues) {
      state.upcomingLeagues = leagues;
    },
    SET_LIVE_LEAGUES(state, leagues) {
      state.liveLeagues = leagues;
    },
    SET_LIVE_SPORTS(state, sports) {
      state.liveSports = sports;
    },

    UPDATE_LIVE_EVENTS_COUNT(state, { sportId, count }) {
      console.log(`Updating count for sport ${sportId} to ${count}`);
      state.liveEventsCounts = {
        ...state.liveEventsCounts,
        [sportId]: count
      };
    },

    UPDATE_LIVE_MATCH(state, matchData) {
      // Find the league that contains this match
      const leagueIndex = state.liveLeagues.data.findIndex(league =>
        league.matches.some(match => match.id === matchData.id)
      );

      if (leagueIndex !== -1) {
        // Find the match within the league
        const matchIndex = state.liveLeagues.data[leagueIndex].matches.findIndex(match =>
          match.id === matchData.id
        );

        if (matchIndex !== -1) {
          // Update the match data
          state.liveLeagues.data[leagueIndex].matches[matchIndex] = {
            ...state.liveLeagues.data[leagueIndex].matches[matchIndex],
            ...matchData
          };
        }
      }
    },
    SET_LOGIN_MODAL(state, isOpen) {
      state.isLoginModalOpen = isOpen
    },
    SET_LANGUAGE(state, language) {
      state.language = language;
    },
    SET_CURRENT_LANGUAGE(state, language) {
      state.currentLanguage = language;
    }
  },
  actions: {
    toggleLoginModal({ commit }, isOpen) {
      commit('SET_LOGIN_MODAL', isOpen)
    },
    async fetchAndCacheData({ commit }, { key, fetchFunction, commitType, forceUpdate = false }) {
      if (!forceUpdate && !shouldFetchData(key)) {
        const cachedData = loadFromLocalStorage(key);

        console.log(`Fetching cached data for ${key}:`, cachedData);
        commit(commitType, cachedData.data);
        return;
      }

      try {
        const data = await fetchFunction();

        console.log(`Fetched data for ${key}:`, data);
        commit(commitType, data);
        saveToLocalStorage(key, { data, timestamp: Date.now() });
      } catch (error) {
        console.error(`Error fetching ${key}:`, error);
      }
    },
    async fetchSports({ dispatch }) {
      await dispatch('fetchAndCacheData', {
        key: 'sports',
        fetchFunction: async () => {
          const response = await fetch(`https://api.newbet365.org/api/sports`);
          return response.json();
        },
        commitType: 'SET_SPORTS'
      });
    },
    async updateLiveEventsCount({ commit }, sportId) {
      try {
        console.log(`Fetching live events for sport ${sportId}`);
        const response = await fetch(`https://api.newbet365.org/api/feed/live/${sportId}`);
        const data = await response.json();


        // Count the number of items in the data array
        const count = data.data.length;
        console.log(`Live events count for sport ${sportId}:`, count);

        commit('UPDATE_LIVE_EVENTS_COUNT', {
          sportId: parseInt(sportId),
          count: count
        });
      } catch (error) {
        console.error(`Error updating live events count for sport ${sportId}:`, error);
      }
    },
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language);
    },
    setCurrentLanguage({ commit }, language) {
      commit('SET_CURRENT_LANGUAGE', language);
    },

    async fetchLiveSports({ commit, dispatch }) {
      try {
        const response = await fetch('https://api.newbet365.org/api/sports/live');
        const data = await response.json();

        console.log('Fetched live sports:', data);

        if (data.data && Array.isArray(data.data)) {
          // Set live sports data
          commit('SET_LIVE_SPORTS', data.data);

          // Immediately fetch live counts for each sport
          data.data.forEach(sport => {
            dispatch('updateLiveEventsCount', sport.id);
          });
        }
      } catch (error) {
        console.error("Error fetching live sports:", error);
      }
    },

    startLiveEventsCountUpdates({ dispatch, state }) {
      // Initial update
      state.liveSports.forEach(sport => {
        dispatch('updateLiveEventsCount', sport.id);
      });

      // Set up interval for updates
      const intervalId = setInterval(() => {
        state.liveSports.forEach(sport => {
          dispatch('updateLiveEventsCount', sport.id);
        });
      }, 5 * 60 * 1000); // 5 minutes

      // Store interval ID to clear it later if needed
      state.liveUpdateInterval = intervalId;
    },

    async fetchTodaysMatches({ dispatch, state }) {
      const sportId = state.currentSportId;
      await dispatch('fetchAndCacheData', {
        key: `todaysMatches_${sportId}`,
        fetchFunction: async () => {
          const response = await fetch(`https://api.newbet365.org/api/feed/today/${sportId}`);
          return response.json();
        },
        commitType: 'SET_TODAYS_MATCHES'
      });
    },
    async fetchUpcomingMatches({ dispatch, state }) {
      const sportId = state.currentSportId;
      await dispatch('fetchAndCacheData', {
        key: `upcomingMatches_${sportId}`,
        fetchFunction: async () => {
          const response = await fetch(`https://api.newbet365.org/api/feed/upcoming/${sportId}`);
          return response.json();
        },
        commitType: 'SET_UPCOMING_MATCHES'
      });
    },
    async fetchLiveEvents({ commit, state }) {
      const sportId = state.currentLiveSportId;
      commit('SET_LOADING', true);
      try {
        const response = await fetch(`https://api.newbet365.org/api/feed/live/${sportId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        commit('SET_LIVE_LEAGUES', data);
      } catch (error) {
        console.error("Error fetching live events:", error);
      } finally {
        commit('SET_LOADING', false);
      }
    },


    updateLiveMatch({ commit }, matchData) {
      commit('UPDATE_LIVE_MATCH', matchData);
    },



    async fetchFeaturedLeague({ commit, state }) {
      const sportId = state.currentSportId;
      // await dispatch('fetchAndCacheData', {
      //   key: `featuredLeague_${sportId}`,
      //   fetchFunction: async () => {
      commit('SET_LOADING', true);
      const response = await fetch(`https://api.newbet365.org/api/feed/featured-league/${sportId}`);
      const league = await response.json();

      commit('SET_FEATURED_LEAGUE', league);
      console.log("Featured league from store:", league);
      commit('SET_LOADING', false);
      //   },
      //   commitType: 'SET_FEATURED_LEAGUE'
      // });
    },
    async fetchCountries({ dispatch, state }) {
      const sportId = state.currentSportId;
      await dispatch('fetchAndCacheData', {
        key: `countries_${sportId}`,
        fetchFunction: async () => {
          const response = await fetch(`https://api.newbet365.org/api/countries/${sportId}`);
          return response.json();
        },
        commitType: 'SET_COUNTRIES',
        forceUpdate: true
      });
    },
    async fetchLeagues({ dispatch, state }) {
      const sportId = state.currentSportId;
      const countryId = state.currentCountryId;
      await dispatch('fetchAndCacheData', {
        key: `leagues_${sportId}_${countryId}`,
        fetchFunction: async () => {
          const response = await fetch(`https://api.newbet365.org/api/feed/competitions/${countryId}/${sportId}`);
          return response.json();
        },
        commitType: 'SET_LEAGUES'
      });
    },
    async updateCurrentSportId({ commit, dispatch }, id) {
      commit('SET_LOADING', true);
      commit('SET_CURRENT_SPORT_ID', id);
      await Promise.all([
        dispatch('fetchTodaysMatches'),
        dispatch('fetchUpcomingMatches'),
        dispatch('fetchFeaturedLeague'),
        dispatch('fetchCountries'),
      ]);
      commit('SET_LOADING', false);
    },

    async updateCurrentLiveSportId({ commit}, id) {
      commit('SET_LOADING', true);
      commit('SET_CURRENT_LIVE_SPORT_ID', id);
      // await dispatch('fetchLiveEvents');
      commit('SET_LOADING', false);
    },
    async updateCurrentCountryId({ commit, dispatch }, id) {
      commit('SET_LOADING', true);
      commit('SET_CURRENT_COUNTRY_ID', id);
      await dispatch('fetchLeagues');
      commit('SET_LOADING', false);
    },

    async fetchMatch({ commit }, id) {
      // commit('SET_LOADING', true);
      const response = await fetch(`https://api.newbet365.org/api/matches/${id}`);
      const match = await response.json();

      console.log("Match from store:", match);
      commit('SET_SELECTED_MATCH', match);
      commit('SET_LOADING', false);
    },

    async fetchLeagueData({ commit }, { leagueId, sportId }) {
      commit('SET_LOADING', true);
      try {
        const response = await fetch(`https://api.newbet365.org/api/feed/matches/${leagueId}/${sportId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const league = await response.json();
        console.log("League data from store:", league);
        commit('SET_SELECTED_LEAGUE', league);
      } catch (error) {
        console.error("Error fetching league data:", error);
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchUpcomingLeagues({ commit }, sportId) {
      commit('SET_LOADING', true);
      try {
        const response = await fetch(`https://api.newbet365.org/api/feed/upcoming/leagues/${sportId}`);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        commit('SET_UPCOMING_LEAGUES', data.data);
      } catch (error) {
        console.error("Error fetching upcoming leagues:", error);
        throw error;
      } finally {
        commit('SET_LOADING', false);
      }
    },

    async fetchHomePageData({ dispatch }) {
      try {
        await Promise.all([
          dispatch('fetchSports'),
          dispatch('fetchTodaysMatches'),
          dispatch('fetchUpcomingMatches'),
          dispatch('fetchFeaturedLeague'),
        ]);
      } catch (error) {
        console.error("Error fetching home page data:", error);
      }
    },

    async fetchLiveEventsPageData({ dispatch }) {
      try {
        // console.log('Fetching live events page data...');
        await Promise.all([
          dispatch('fetchLiveEvents'),
          dispatch('fetchLiveSports'),
          dispatch('fetchSports')
        ]);
        dispatch('startLiveEventsCountUpdates');
        // console.log('Live events page data fetched successfully');
      } catch (error) {
        console.error("Error fetching live events page data:", error);
      }
    },



    async fetchPrecountriesPageData({ dispatch }) {
      try {
        await Promise.all([
          dispatch('fetchSports'),
          dispatch('fetchFeaturedLeague'),
          dispatch('fetchCountries'),
        ]);
      } catch (error) {
        console.error("Error fetching precountries page data:", error);
      }
    },
    async fetchPreleaguesPageData({ dispatch, commit }, { sportId, countryId }) {
      try {
        commit('SET_CURRENT_SPORT_ID', sportId);
        commit('SET_CURRENT_COUNTRY_ID', countryId);
        await Promise.all([
          dispatch('fetchSports'),
          dispatch('fetchFeaturedLeague'),
          dispatch('fetchLeagues'),
        ]);
      } catch (error) {
        console.error("Error fetching preleagues page data:", error);
      }
    },
    async fetchMatchDetailPageData({ dispatch }, matchId) {
      try {
        await Promise.all([
          dispatch('fetchMatch', matchId),
          dispatch('fetchSports')
        ]);
      } catch (error) {
        console.error("Error fetching match detail page data:", error);
        throw error; // Re-throw the error so it can be caught in the component
      }
    },

    async fetchPrematchPageData({ dispatch }, leagueId, sportId) {
      try {
        await Promise.all([
          dispatch('fetchLeagueData', leagueId, sportId),
          dispatch('fetchSports')
        ]);
      } catch (error) {
        console.error("Error fetching match detail page data:", error);
        throw error; // Re-throw the error so it can be caught in the component
      }
    },
    async fetchPrematchLeaguesPageData({ dispatch }, sportId) {
      try {
        await Promise.all([
          dispatch('fetchUpcomingLeagues', sportId),
          dispatch('fetchSports')
        ]);
      } catch (error) {
        console.error("Error fetching match detail page data:", error);
        throw error; // Re-throw the error so it can be caught in the component
      }
    },

  },

  modules: {
    betslip,
    auth
  },
});