// store/betslip.js
import axios from 'axios'
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('betslipState')
    if (serializedState === null) {
      return undefined
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return undefined
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem('betslipState', serializedState)
  } catch (err) {
    // Ignore write errors
  }
}

const initialState = loadState() || {
  selections: [],
  stake: 0,
  isVisible: false,
  error: null
}

export const betslip = {
  namespaced: true,

  state: initialState,

  getters: {
    totalOdds: (state) => {
      return state.selections.reduce((total, selection) => total * selection.odds, 1)
    },

    potentialWinnings: (state, getters) => {
      return (state.stake * getters.totalOdds).toFixed(2)
    },

    selectionCount: (state) => state.selections.length,

    isSelected: (state) => (matchId, marketId) => {
      return state.selections.some(s => s.matchId === matchId && s.marketId === marketId)
    },

    formattedBetData: (state, getters, rootState, rootGetters) => {
      const user = rootGetters['user']
      const betData = {
        betUniqueId: "",
        walletId: user?.wallets?.[0]?.id ?? null,
        user_id: user?.id
      }

      if (state.selections.length === 1) {
        const selection = state.selections[0]
        betData.singles = [{
          stake: state.stake,
          match_id: selection.matchId,
          odd_id: selection.oddId,
          market_id: selection.marketId,
          odds: selection.odds
        }]
      } else {
        betData.multipleStake = state.stake
        betData.multiples = state.selections.map(selection => ({
          match_id: selection.matchId,
          odd_id: selection.oddId,
          market_id: selection.marketId,
          odds: selection.odds
        }))
      }

      return betData
    },

    canPlaceBet: (state, getters, rootState, rootGetters) => {
      const user = rootGetters['user']


      if (!user) return { allowed: false, reason: 'User not authenticated' }
      if (user.role !== 'user') return { allowed: false, reason: 'You have no permissions to place bets' }
      if (!user.wallets || user.wallets.length === 0) return { allowed: false, reason: 'User has no wallet' }
      const walletBalance = parseFloat(user.wallets[0].balance)
      // console.log('Wallet balance',user.wallets[0])
      if (walletBalance < state.stake) return { allowed: false, reason: 'Insufficient balance in wallet' }
      if (state.selections.length === 0) return { allowed: false, reason: 'No selections made' }
      if (state.stake <= 0) return { allowed: false, reason: 'Invalid stake amount' }
      return { allowed: true, reason: '' }
    },

    error: state => state.error
  },

  mutations: {
    ADD_SELECTION(state, selection) {
      const existingIndex = state.selections.findIndex(
        s => s.matchId === selection.matchId && s.marketId === selection.marketId
      )
      if (existingIndex !== -1) {
        state.selections.splice(existingIndex, 1, selection)
      } else {
        state.selections.push(selection)
      }
      saveState(state)
    },

    REMOVE_SELECTION(state, { matchId, marketId }) {
      state.selections = state.selections.filter(
        s => !(s.matchId === matchId && s.marketId === marketId)
      )
      saveState(state)
    },

    UPDATE_STAKE(state, stake) {
      state.stake = parseFloat(stake) || 0
      saveState(state)
    },

    CLEAR_BETSLIP(state) {
      state.selections = []
      state.stake = 0
      state.error = null
      saveState(state)
    },

    TOGGLE_VISIBILITY(state, value) {
      state.isVisible = value
      saveState(state)
    },

    SET_ERROR(state, error) {
      state.error = error
    }
  },

  actions: {
    addOrRemoveSelection({ commit, state }, selection) {
      const existingIndex = state.selections.findIndex(
        s => s.matchId === selection.matchId && s.marketId === selection.marketId
      );
      if (existingIndex !== -1) {
        commit('REMOVE_SELECTION', { matchId: selection.matchId, marketId: selection.marketId });
      } else {
        commit('ADD_SELECTION', selection);
      }
    },

    updateStake({ commit }, stake) {
      commit('UPDATE_STAKE', stake)
    },

    clearBetslip({ commit }) {
      commit('CLEAR_BETSLIP')
    },

    toggleVisibility({ commit }, value) {
      commit('TOGGLE_VISIBILITY', value)
    },

    async placeBet({ commit, getters }) {
      const canPlaceBet = getters.canPlaceBet
      if (!canPlaceBet.allowed) {
        commit('SET_ERROR', canPlaceBet.reason)
        return false
      }
    
      try {
        const betData = getters.formattedBetData

        console.log(" Bet data: ", betData);
        const authData = JSON.parse(localStorage.getItem("forbet_auth") || "{}")
        const token = authData.accessToken
    
        const response = await axios.post('https://api.newbet365.org/api/bets', betData, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        })
    
        console.log('Place bet response:', response.data)
    
        if (response.data && response.data.data && response.data.data.betUniqueId && response.data.data.wait) {
          // Wait for the specified time
          await new Promise(resolve => setTimeout(resolve, response.data.data.wait))
    
          // Attempt to place the bet again with the betUniqueId
          betData.betUniqueId = response.data.data.betUniqueId
          const retryResponse = await axios.post('https://api.newbet365.org/api/bets', betData, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
    
          console.log('Retry bet response:', retryResponse.data)
    
          if (retryResponse.data && Array.isArray(retryResponse.data.data) && retryResponse.data.data.length > 0) {
            commit('CLEAR_BETSLIP')
            return true
          } else {
            commit('SET_ERROR', 'Failed to place bet: No bet data received')
            return false
          }
        } else if (response.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
          commit('CLEAR_BETSLIP')
          return true
        } else {
          commit('SET_ERROR', 'Failed to place bet: No bet data received')
          return false
        }
      } catch (error) {
        console.error('Error placing bet:', error)
        commit('SET_ERROR', error.response?.data?.message || 'Failed to place bet')
        return false
      }
    }
  }
}

