import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CreateUserView from "../views/CreateUserView.vue";
import ChangePasswordView from "../views/ChangePasswordView.vue";
import AccountBalanceView from "../views/AccountBalanceView.vue";
import MyBetsView from "../views/MyBetsView.vue";
import LoginView from "../views/LoginView.vue";
import UsersListView from "../views/UsersListView.vue";
import LiveMatchDetailView from "../views/LiveMatchDetailView.vue";
import WithdrawView from "../views/WithdrawView.vue";
import WithdrawalsView from "../views/WithdrawalsView.vue";
import WithdrawalRequestsView from "../views/WithdrawalRequestsView.vue";
import store from '../store';

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/users",
    name: "users",
    component: UsersListView,
  },
  {
    path: '/user/:id',
    name: 'UserDetails',
    component: () => import('../views/UserDetailsView.vue')
  },
  {
    path: "/balance",
    name: "balance",
    component: AccountBalanceView,
    meta: { requiresAuth: true }
  },
  {
    path: "/my-bets",
    name: "mybets",
    component: MyBetsView,
    meta: { requiresAuth: true }
  },
  {
    path: "/create-user",
    name: "create-user",
    component: CreateUserView,
    meta: { requiresAuth: true }
  },
  {
    path: "/change-password",
    name: "change-password",
    component: ChangePasswordView,
    meta: { requiresAuth: true }
  },
  {
    path: "/precountries/:countryId",
    name: "precountries",
    component: () => import("../views/PrecountriesView.vue"),
  },
  {
    path: "/preleagues/:countryId/:sportId",
    name: "Preleagues",
    component: () => import("../views/PreleaguesView.vue"),
  },
  {
    path: "/matchdetail/:matchId",
    name: "MatchDetail",
    component: () => import("../views/MatchDetailView.vue"),
  },
  {
    path: "/livedetail/:matchId",
    name: "LiveDetail",
    component: LiveMatchDetailView,
  },
  {
    path: "/prematch/:leagueId/:sportId",
    name: "Prematch",
    component: () => import("../views/PrematchView.vue"),
  },
  {
    path: "/prematch/:sportId",
    name: "PrematchLeagues",
    component: () => import("../views/PrematchLeaguesView.vue"),
  },
  {
    path: "/inplay/:sportId",
    name: "Inplay",
    component: () => import("../views/LiveEventsView.vue"),
  },
  {
    path: "/withdraw",
    name: "withdraw",
    component: WithdrawView,
    meta: { requiresAuth: true }
  },
  {
    path: "/withdrawals",
    name: "withdrawals",
    component: WithdrawalsView,
    meta: { requiresAuth: true }
  },
  {
    path: "/withdrawal-requests",
    name: "withdrawal-requests",
    component: WithdrawalRequestsView,
    meta: { requiresAuth: true }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['isAuthenticated']) {
      store.dispatch('toggleLoginModal', true);
      next(false);
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;