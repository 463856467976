<template>
  <div class="min-h-screen bg-gray-100">
    <template v-if="isLoading">
      <div class="p-4 max-w-7xl mx-auto">
        <SimpleSpinner />
      </div>
    </template>
    <template v-else>
      <MainLayout :sports="sports">
        <div class="bg-custom-black py-8 px-4">
          <div class="w-full max-w-md mx-auto">
            <h2 class="text-2xl font-bold text-white mb-6 text-center">{{ t('changePassword.title') }}</h2>
            <form @submit.prevent="handleSubmit" class="space-y-6" autocomplete="off">
              <!-- Current Password -->
              <div class="space-y-2">
                <label class="block text-gray-300 text-sm">{{ t('changePassword.currentPassword') }}</label>
                <div class="bg-white rounded-lg overflow-hidden">
                  <input
                    v-model="formData.currentPassword"
                    type="password"
                    class="w-full p-4 text-bg-custom-black placeholder-gray-500 focus:outline-none"
                    required
                    autocomplete="off"
                  />
                </div>
              </div>

              <!-- New Password -->
              <div class="space-y-2">
                <label class="block text-gray-300 text-sm">{{ t('changePassword.newPassword') }}</label>
                <div class="bg-white rounded-lg overflow-hidden">
                  <input
                    v-model="formData.newPassword"
                    type="password"
                    class="w-full p-4 text-bg-custom-black placeholder-gray-500 focus:outline-none"
                    required
                    autocomplete="new-password"
                  />
                </div>
              </div>

              <!-- Confirm Password -->
              <div class="space-y-2">
                <label class="block text-gray-300 text-sm">{{ t('changePassword.confirmPassword') }}</label>
                <div class="bg-white rounded-lg overflow-hidden">
                  <input
                    v-model="formData.confirmPassword"
                    type="password"
                    class="w-full p-4 text-bg-custom-black placeholder-gray-500 focus:outline-none"
                    required
                    autocomplete="new-password"
                  />
                </div>
              </div>

              <!-- Submit Button -->
              <button
                type="submit"
                class="w-full bg-custom-bg-green text-white p-4 rounded-lg hover:bg-custom-green transition-colors duration-200"
                :disabled="isSubmitting"
              >
                {{ isSubmitting ? t('changePassword.processing') : t('changePassword.save') }}
              </button>
            </form>
          </div>
        </div>
      </MainLayout>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import MainLayout from "../layouts/MainLayout.vue"
import SimpleSpinner from "../components/loaders/SimpleSpinner.vue"

const store = useStore()
const router = useRouter()
const { t } = useI18n()
const isSubmitting = ref(false)
const isLoading = computed(() => store.state.isLoading)
const sports = computed(() => store.state.sports || [])

const formData = ref({
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
})

const handleSubmit = async () => {
  if (formData.value.newPassword !== formData.value.confirmPassword) {
    alert(t('changePassword.passwordMismatch'))
    return
  }

  try {
    isSubmitting.value = true
    await store.dispatch('changePassword', {
      currentPassword: formData.value.currentPassword,
      newPassword: formData.value.newPassword
    })
    router.push('/profile') // Or wherever you want to redirect after success
  } catch (error) {
    console.error(t('changePassword.failedToChange'), error)
    // Handle error (show error message, etc.)
  } finally {
    isSubmitting.value = false
  }
}

onMounted(async () => {
  console.log(t('changePassword.componentMounted'))
  try {
    await store.dispatch("fetchSports")
    console.log(t('changePassword.sportsFetched'))
  } catch (error) {
    console.error(t('changePassword.failedToFetchSports'), error)
  }
})
</script>

<style scoped>
/* Add any additional styles here */
</style>