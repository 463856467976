
export default {
    en: {
        header: {
            az: 'A-Z',
            inPlay: 'In Play',
            logoAlt: 'itbet365 logo',
            myBets: 'My Bets',
            login: 'Login'
        },
        footer: {
            backToTop: 'Back To Top',
            footerTitle: 'itbet365 Sport',
            termsAndConditions: 'Terms and Conditions',
            responsibleGambling: 'Responsible Gambling',
            privacyPolicy: 'Privacy Policy',
            cookiesPolicy: 'By accessing, continuing to use or navigating throughout this site you accept that we will use certain browser cookies to improve your customer experience with us. Our site only uses cookies which will improve your experience with us and will not interfere with your privacy. Please refer to our Cookies Policy for further information on our use of cookies and how you can disable or manage their use should you wish.',
            responsibleGamblingMessage: 'Gambling can be addictive, please play responsibly. For information on support measures, please visit our Responsible Gambling Help page.',
            copyrightNotice: '© 2016-2023 All rights reserved.'
        },
        userAccountPanel: {
            account: 'Account',
            username: 'Username',
            notAvailable: 'N/A',
            balance: '{currency} Balance',
            logout: 'Logout',
            messages: 'Messages',
            usersList: 'Users list',
            withdrawals: 'Withdrawals',
            withdraw: 'Withdraw',
            withdrawalRequests: 'Withdrawal Requests',
            accountBalance: 'Account Balance',
            createNewUser: 'Create New User',
            changePassword: 'Change password',
            infoAndRegulations: 'Info and Regulations'
        },
        userDetails: {
            goBack: 'Go back',
            title: 'User Details',
            userInformation: 'User Information',
            lastLogin: 'Last Login',
            username: 'Username',
            firstName: 'First Name',
            surname: 'Surname',
            balanceInformation: 'Balance Information',
            currency: 'Currency',
            balance: 'Balance'
        },
        loginModal: {
            close: 'Close',
            username: 'Username',
            password: 'Password',
            savePassword: 'Save Password',
            loading: 'Loading...',
            login: 'Login',
            lostLogin: 'Lost Login?'
        },
        myBets: {
            title: 'My Bets',
            filter: 'Filter',
            today: 'Today',
            selectCurrency: 'Select Currency',
            noBetsFound: 'No bets found',
            cancel: 'Cancel',

            yesterday: 'Yesterday',
            tabs: {
                sports: 'Sports',
                virtualSoccer: 'Virtual Soccer',
                virtualGames: 'Virtual Games'
            },
            betNumber: 'N°{id}',
            betStatus: {
                won: 'Won',
                lost: 'Lost',
                open: 'Pending',
                void: 'Void',
                cancelled: 'Cancelled'

            },
            currency: 'LEK',
            amount: 'Amount',
            toReturn: 'To Return',
            all: 'All'
        },
        accountBalance: {
            title: 'Account Balance',
            all: 'All',
            filter: 'Filter',
            quickFilters: {
                today: "Today",
                yesterday: "Yesterday",
                currentMonth: "This Month",
                currentYear: "This Year"
            },
            table: {
                info: 'Info',
                totalBets: 'Total Bets',
                winnings: 'Winnings',
                commission: 'Commission',
                netReturn: 'Net Return',
                all: 'All'
            }
        },
        usersList: {
            seniorsList: 'Seniors List',
            managersList: 'Managers List',
            agentsList: 'Agents List',
            usersList: 'Users List',
            myBalance: 'My Balance',
            balance: 'Balance',
            balanceForEachUser: 'Balance for each user',
            negative: 'Negative',
            positive: 'Positive',
            username: 'Username',
            errorFetchingData: 'Failed to load user data. Please try again.'
        },
        newUser: {
            createNewUser: 'Create New User',
            username: 'Username',
            password: 'Password',
            firstName: 'First Name (Optional)',
            lastName: 'Last Name (Optional)',
            email: 'Email',
            maxBet: "Max Bet",
            minBet: "Min Bet",
            maxBetProfit: "Max Bet Profit",
            allCurrencies: 'All Currencies',
            processing: 'Processing...',
            register: 'Register',
            errors: {
                usernameRequired: 'Username is required',
                passwordRequired: 'Password is required',
                emailRequired: 'Email is required',
                currencyRequired: 'Currency is required',
                authTokenNotFound: 'Authentication token not found',
                failedToCreateUser: 'Failed to create user'
            },
            userCreatedSuccessfully: 'User created successfully:',
            currentUser: 'Current user:',
            currentUserWallets: 'Current user wallets:',
            availableCurrencies: 'Available currencies:'
        },
        changePassword: {
            title: 'Change password',
            currentPassword: 'Current password',
            newPassword: 'New Password',
            confirmPassword: 'Confirm Password',
            processing: 'Processing...',
            save: 'Save',
            passwordMismatch: 'New password and confirm password do not match',
            failedToChange: 'Failed to change password:',
            componentMounted: 'ChangePasswordView is mounted, about to fetch data.',
            sportsFetched: 'Sports data fetched successfully.',
            failedToFetchSports: 'Failed to fetch sports data:'
        },
        userDetailsPage: {
            goBack: 'Go back',
            title: 'User Details',
            loading: 'Loading user details...',
            userInformation: 'User Information',
            username: 'Username',
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            balanceInformation: 'Balance Information',
            currency: 'Currency',
            balance: 'Balance',
            noUserData: 'No user data available.',
            transferBalance: 'Transfer Balance {code}',
            amount: 'Amount',
            processing: 'Processing...',
            save: 'Save',
            errors: {
                authTokenNotFound: 'Authentication token not found',
                failedToFetchUserDetails: 'Failed to fetch user details',
                errorFetchingUserDetails: 'Error fetching user details:',
                userNotFound: 'User not found',
                failedToLoadUserDetails: 'Failed to load user details. Please try again.',
                enterValidAmount: 'Please enter a valid amount',
                transferFailed: 'Transfer failed',
                transferError: 'Transfer error:',
                failedToProcessTransfer: 'Failed to process transfer. Please try again.'
            }
        },
        withdrawal: {
            title: 'Withdrawal Request',
            requestWithdrawal: 'Request Withdrawal',
            currentBalance: 'Current Balance',
            amount: 'Amount',
            submit: 'Submit',
            processing: 'Processing...',
            success: 'Withdrawal request submitted successfully',
            errors: {
                invalidAmount: 'Please enter a valid amount',
                currencyRequired: 'Please select a currency',
                authTokenNotFound: 'Authentication token not found',
                failedToSubmitRequest: 'Failed to submit withdrawal request'
            }
        },
    },
    sq: { // Albanian (Shqip)
        header: {
            az: 'A-Z',
            inPlay: 'Në Lojë',
            logoAlt: 'itbet365 logo',
            myBets: 'Bastet e Mia',
            login: 'Hyr'
        },
        footer: {
            backToTop: 'Kthehu Lart',
            footerTitle: 'itbet365 Sport',
            termsAndConditions: 'Termat dhe Kushtet',
            responsibleGambling: 'Lojëra të Përgjegjshme',
            privacyPolicy: 'Politika e Privatësisë',
            cookiesPolicy: 'Duke aksesuar, vazhduar të përdorni ose naviguar në këtë faqe, ju pranoni që ne do të përdorim disa cookie të shfletuesit për të përmirësuar përvojën tuaj të klientit me ne. Faqja jonë përdor vetëm cookie që do të përmirësojnë përvojën tuaj me ne dhe nuk do të ndërhyjnë në privatësinë tuaj. Ju lutemi referojuni Politikës sonë të Cookie-ve për më shumë informacion mbi përdorimin e cookie-ve dhe si mund t\'i çaktivizoni ose menaxhoni ato nëse dëshironi.',
            responsibleGamblingMessage: 'Lojërat e fatit mund të krijojnë varësi, ju lutemi luani me përgjegjësi. Për informacion mbi masat e mbështetjes, ju lutemi vizitoni faqen tonë të Ndihmës për Lojëra të Përgjegjshme.',
            copyrightNotice: '© 2016-2023 Të gjitha të drejtat të rezervuara.'
        },
        userAccountPanel: {
            account: 'Llogaria',
            username: 'Emri i përdoruesit',
            notAvailable: 'N/A',
            balance: 'Bilanci në {currency}',
            logout: 'Dilni',
            messages: 'Mesazhet',
            usersList: 'Lista e përdoruesve',
            accountBalance: 'Bilanci i llogarisë',
            createNewUser: 'Krijo Përdorues të Ri',
            changePassword: 'Ndrysho fjalëkalimin',
            infoAndRegulations: 'Informacione dhe Rregullore'
        },
        userDetails: {
            goBack: 'Kthehu',
            title: 'Detajet e Përdoruesit',
            userInformation: 'Informacioni i Përdoruesit',
            lastLogin: 'Hyrja e Fundit',
            username: 'Emri i përdoruesit',
            firstName: 'Emri',
            surname: 'Mbiemri',
            balanceInformation: 'Informacioni i Bilancit',
            currency: 'Valuta',
            balance: 'Bilanci'
        },
        loginModal: {
            close: 'Mbyll',
            username: 'Emri i përdoruesit',
            password: 'Fjalëkalimi',
            savePassword: 'Ruaj Fjalëkalimin',
            loading: 'Duke u ngarkuar...',
            login: 'Hyr',
            lostLogin: 'Humbët të dhënat e hyrjes?'
        },
        myBets: {
            title: 'Bastet e Mia',
            filter: 'Filtro',
            tabs: {
                sports: 'Sporte',
                virtualSoccer: 'Futboll Virtual',
                virtualGames: 'Lojëra Virtuale'
            },
            betNumber: 'Nr.{id}',
            betStatus: {
                won: 'Fituar',
                lost: 'Humbur',
                open: 'Në pritje'
            },
            currency: 'LEK',
            amount: 'Shuma',
            toReturn: 'Për t\'u kthyer',
            all: 'Të gjitha'
        },
        accountBalance: {
            title: 'Bilanci i Llogarisë',
            all: 'Të gjitha',
            filter: 'Filtro',
            quickFilters: {
                today: 'Sot',
                yesterday: 'Dje',
                december: 'Dhjetor',
                november: 'Nëntor'
            },
            table: {
                info: 'Informacion',
                totalBets: 'Totali i Basteve',
                winnings: 'Fitimet',
                commission: 'Komisioni',
                netReturn: 'Kthimi Neto'
            }
        },
        usersList: {
            seniorsList: 'Lista e Seniorëve',
            managersList: 'Lista e Menaxherëve',
            agentsList: 'Lista e Agjentëve',
            usersList: 'Lista e Përdoruesve',
            myBalance: 'Bilanci Im',
            balance: 'Bilanci',
            balanceForEachUser: 'Bilanci për çdo përdorues',
            negative: 'Negativ',
            positive: 'Pozitiv',
            username: 'Emri i përdoruesit',
            errorFetchingData: 'Dështoi në ngarkimin e të dhënave të përdoruesit. Ju lutemi provoni përsëri.'
        },
        newUser: {
            createNewUser: 'Krijo Përdorues të Ri',
            username: 'Emri i përdoruesit',
            password: 'Fjalëkalimi',
            firstName: 'Emri (Opsionale)',
            lastName: 'Mbiemri (Opsionale)',
            email: 'Email',
            allCurrencies: 'Të gjitha Monedhat',
            processing: 'Duke përpunuar...',
            register: 'Regjistrohu',
            errors: {
                usernameRequired: 'Emri i përdoruesit është i detyrueshëm',
                passwordRequired: 'Fjalëkalimi është i detyrueshëm',
                emailRequired: 'Email-i është i detyrueshëm',
                currencyRequired: 'Monedha është e detyrueshme',
                authTokenNotFound: 'Tokeni i autentifikimit nuk u gjet',
                failedToCreateUser: 'Dështoi krijimi i përdoruesit'
            },
            userCreatedSuccessfully: 'Përdoruesi u krijua me sukses:',
            currentUser: 'Përdoruesi aktual:',
            currentUserWallets: 'Portofoli i përdoruesit aktual:',
            availableCurrencies: 'Monedhat e disponueshme:'
        },
        changePassword: {
            title: 'Ndrysho fjalëkalimin',
            currentPassword: 'Fjalëkalimi aktual',
            newPassword: 'Fjalëkalimi i ri',
            confirmPassword: 'Konfirmo fjalëkalimin',
            processing: 'Duke përpunuar...',
            save: 'Ruaj',
            passwordMismatch: 'Fjalëkalimi i ri dhe konfirmimi i fjalëkalimit nuk përputhen',
            failedToChange: 'Dështoi ndryshimi i fjalëkalimit:',
            componentMounted: 'ChangePasswordView është montuar, gati për të marrë të dhënat.',
            sportsFetched: 'Të dhënat e sporteve u morën me sukses.',
            failedToFetchSports: 'Dështoi marrja e të dhënave të sporteve:'
        },
        userDetailsPage: {
            goBack: 'Kthehu',
            title: 'Detajet e Përdoruesit',
            loading: 'Duke ngarkuar detajet e përdoruesit...',
            userInformation: 'Informacioni i Përdoruesit',
            username: 'Emri i përdoruesit',
            firstName: 'Emri',
            lastName: 'Mbiemri',
            email: 'Email',
            balanceInformation: 'Informacioni i Bilancit',
            currency: 'Valuta',
            balance: 'Bilanci',
            noUserData: 'Nuk ka të dhëna të disponueshme për përdoruesin.',
            transferBalance: 'Transfero Bilancin {code}',
            amount: 'Shuma',
            processing: 'Duke përpunuar...',
            save: 'Ruaj',
            errors: {
                authTokenNotFound: 'Tokeni i autentifikimit nuk u gjet',
                failedToFetchUserDetails: 'Dështoi në marrjen e detajeve të përdoruesit',
                errorFetchingUserDetails: 'Gabim në marrjen e detajeve të përdoruesit:',
                userNotFound: 'Përdoruesi nuk u gjet',
                failedToLoadUserDetails: 'Dështoi në ngarkimin e detajeve të përdoruesit. Ju lutemi provoni përsëri.',
                enterValidAmount: 'Ju lutemi vendosni një shumë të vlefshme',
                transferFailed: 'Transferta dështoi',
                transferError: 'Gabim në transfertë:',
                failedToProcessTransfer: 'Dështoi në përpunimin e transfertës. Ju lutemi provoni përsëri.'
            }
        },
        withdrawal: {
            title: 'Kërkesë për Tërheqje',
            requestWithdrawal: 'Kërko Tërheqje',
            currentBalance: 'Bilanci Aktual',
            amount: 'Shuma',
            submit: 'Dërgo',
            processing: 'Duke përpunuar...',
            success: 'Kërkesa për tërheqje u dërgua me sukses',
            errors: {
                invalidAmount: 'Ju lutemi vendosni një shumë të vlefshme',
                currencyRequired: 'Ju lutemi zgjidhni një monedhë',
                authTokenNotFound: 'Tokeni i autentifikimit nuk u gjet',
                failedToSubmitRequest: 'Dështoi dërgimi i kërkesës për tërheqje'
            }
        },
    },
    pt: { // Portuguese (Português)
        header: {
            az: 'A-Z',
            inPlay: 'Ao Vivo',
            logoAlt: 'logo itbet365',
            myBets: 'Minhas Apostas',
            login: 'Entrar'
        },
        footer: {
            backToTop: 'Voltar ao Topo',
            footerTitle: 'itbet365 Sport',
            termsAndConditions: 'Termos e Condições',
            responsibleGambling: 'Jogo Responsável',
            privacyPolicy: 'Política de Privacidade',
            cookiesPolicy: 'Ao acessar, continuar a usar ou navegar neste site, você aceita que usaremos determinados cookies do navegador para melhorar sua experiência como cliente conosco. Nosso site usa apenas cookies que melhorarão sua experiência conosco e não interferirão em sua privacidade. Consulte nossa Política de Cookies para obter mais informações sobre nosso uso de cookies e como você pode desativá-los ou gerenciá-los, se desejar.',
            responsibleGamblingMessage: 'O jogo pode ser viciante, jogue com responsabilidade. Para informações sobre medidas de apoio, visite nossa página de Ajuda ao Jogo Responsável.',
            copyrightNotice: '© 2016-2023 Todos os direitos reservados.'
        },
        userAccountPanel: {
            account: 'Conta',
            username: 'Nome de usuário',
            notAvailable: 'N/D',
            balance: 'Saldo em {currency}',
            logout: 'Sair',
            messages: 'Mensagens',
            usersList: 'Lista de usuários',
            accountBalance: 'Saldo da conta',
            createNewUser: 'Criar Novo Usuário',
            changePassword: 'Alterar senha',
            infoAndRegulations: 'Informações e Regulamentos'
        },
        userDetails: {
            goBack: 'Voltar',
            title: 'Detalhes do Usuário',
            userInformation: 'Informações do Usuário',
            lastLogin: 'Último Login',
            username: 'Nome de usuário',
            firstName: 'Primeiro Nome',
            surname: 'Sobrenome',
            balanceInformation: 'Informações de Saldo',
            currency: 'Moeda',
            balance: 'Saldo'
        },
        loginModal: {
            close: 'Fechar',
            username: 'Nome de usuário',
            password: 'Senha',
            savePassword: 'Salvar Senha',
            loading: 'Carregando...',
            login: 'Entrar',
            lostLogin: 'Perdeu o login?'
        },
        myBets: {
            title: 'Minhas Apostas',
            filter: 'Filtrar',
            tabs: {
                sports: 'Esportes',
                virtualSoccer: 'Futebol Virtual',
                virtualGames: 'Jogos Virtuais'
            },
            betNumber: 'N°{id}',
            betStatus: {
                won: 'Ganhou',
                lost: 'Perdeu',
                open: 'Pendente'
            },
            currency: 'LEK',
            amount: 'Valor',
            toReturn: 'A Retornar',
            all: 'Todos'
        },
        accountBalance: {
            title: 'Saldo da Conta',
            all: 'Todos',
            filter: 'Filtrar',
            quickFilters: {
                today: 'Hoje',
                yesterday: 'Ontem',
                december: 'Dezembro',
                november: 'Novembro'
            },
            table: {
                info: 'Info',
                totalBets: 'Total de Apostas',
                winnings: 'Ganhos',
                commission: 'Comissão',
                netReturn: 'Retorno Líquido'
            }
        },
        usersList: {
            seniorsList: 'Lista de Seniores',
            managersList: 'Lista de Gerentes',
            agentsList: 'Lista de Agentes',
            usersList: 'Lista de Usuários',
            myBalance: 'Meu Saldo',
            balance: 'Saldo',
            balanceForEachUser: 'Saldo para cada usuário',
            negative: 'Negativo',
            positive: 'Positivo',
            username: 'Nome de usuário',
            errorFetchingData: 'Falha ao carregar dados do usuário. Por favor, tente novamente.'
        },
        newUser: {
            createNewUser: 'Criar Novo Usuário',
            username: 'Nome de usuário',
            password: 'Senha',
            firstName: 'Nome (Opcional)',
            lastName: 'Sobrenome (Opcional)',
            email: 'E-mail',
            allCurrencies: 'Todas as Moedas',
            processing: 'Processando...',
            register: 'Registrar',
            errors: {
                usernameRequired: 'Nome de usuário é obrigatório',
                passwordRequired: 'Senha é obrigatória',
                emailRequired: 'E-mail é obrigatório',
                currencyRequired: 'Moeda é obrigatória',
                authTokenNotFound: 'Token de autenticação não encontrado',
                failedToCreateUser: 'Falha ao criar usuário'
            },
            userCreatedSuccessfully: 'Usuário criado com sucesso:',
            currentUser: 'Usuário atual:',
            currentUserWallets: 'Carteiras do usuário atual:',
            availableCurrencies: 'Moedas disponíveis:'
        },
        changePassword: {
            title: 'Alterar senha',
            currentPassword: 'Senha atual',
            newPassword: 'Nova senha',
            confirmPassword: 'Confirmar senha',
            processing: 'Processando...',
            save: 'Salvar',
            passwordMismatch: 'A nova senha e a confirmação da senha não correspondem',
            failedToChange: 'Falha ao alterar a senha:',
            componentMounted: 'ChangePasswordView está montado, prestes a buscar dados.',
            sportsFetched: 'Dados de esportes buscados com sucesso.',
            failedToFetchSports: 'Falha ao buscar dados de esportes:'
        },
        userDetailsPage: {
            goBack: 'Voltar',
            title: 'Detalhes do Usuário',
            loading: 'Carregando detalhes do usuário...',
            userInformation: 'Informações do Usuário',
            username: 'Nome de usuário',
            firstName: 'Nome',
            lastName: 'Sobrenome',
            email: 'E-mail',
            balanceInformation: 'Informações de Saldo',
            currency: 'Moeda',
            balance: 'Saldo',
            noUserData: 'Nenhum dado de usuário disponível.',
            transferBalance: 'Transferir Saldo {code}',
            amount: 'Valor',
            processing: 'Processando...',
            save: 'Salvar',
            errors: {
                authTokenNotFound: 'Token de autenticação não encontrado',
                failedToFetchUserDetails: 'Falha ao buscar detalhes do usuário',
                errorFetchingUserDetails: 'Erro ao buscar detalhes do usuário:',
                userNotFound: 'Usuário não encontrado',
                failedToLoadUserDetails: 'Falha ao carregar detalhes do usuário. Por favor, tente novamente.',
                enterValidAmount: 'Por favor, insira um valor válido',
                transferFailed: 'Transferência falhou',
                transferError: 'Erro na transferência:',
                failedToProcessTransfer: 'Falha ao processar a transferência. Por favor, tente novamente.'
            }
        },
        withdrawal: {
            title: 'Pedido de Retirada',
            requestWithdrawal: 'Solicitar Retirada',
            currentBalance: 'Saldo Atual',
            amount: 'Valor',
            submit: 'Enviar',
            processing: 'Processando...',
            success: 'Pedido de retirada enviado com sucesso',
            errors: {
                invalidAmount: 'Por favor, insira um valor válido',
                currencyRequired: 'Por favor, selecione uma moeda',
                authTokenNotFound: 'Token de autenticação não encontrado',
                failedToSubmitRequest: 'Falha ao enviar o pedido de retirada'
            }
        },
    },
    mk: { // Macedonian (Македонски)
        header: {
            az: 'А-Ш',
            inPlay: 'Во Живо',
            logoAlt: 'itbet365 лого',
            myBets: 'Мои Облози',
            login: 'Најава'
        },
        footer: {
            backToTop: 'Назад кон Врвот',
            footerTitle: 'itbet365 Спорт',
            termsAndConditions: 'Услови за користење',
            responsibleGambling: 'Одговорно Обложување',
            privacyPolicy: 'Политика за приватност',
            cookiesPolicy: 'Со пристапување, продолжување со користење или навигација низ оваа страница, вие прифаќате дека ќе користиме одредени колачиња за да го подобриме вашето корисничко искуство. Нашата страница користи само колачиња кои ќе го подобрат вашето искуство и нема да ја нарушат вашата приватност. Ве молиме погледнете ја нашата Политика за колачиња за повеќе информации.',
            responsibleGamblingMessage: 'Обложувањето може да предизвика зависност, ве молиме обложувајте се одговорно. За информации за мерките за поддршка, посетете ја нашата страница за Помош при Одговорно Обложување.',
            copyrightNotice: '© 2016-2023 Сите права се задржани.'
        },
        userAccountPanel: {
            account: 'Сметка',
            username: 'Корисничко име',
            notAvailable: 'Н/Д',
            balance: 'Баланс во {currency}',
            logout: 'Одјава',
            messages: 'Пораки',
            usersList: 'Листа на корисници',
            accountBalance: 'Состојба на сметка',
            createNewUser: 'Креирај нов корисник',
            changePassword: 'Промени лозинка',
            infoAndRegulations: 'Информации и регулативи'
        },
        userDetails: {
            goBack: 'Назад',
            title: 'Детали за корисникот',
            userInformation: 'Информации за корисникот',
            lastLogin: 'Последно најавување',
            username: 'Корисничко име',
            firstName: 'Име',
            surname: 'Презиме',
            balanceInformation: 'Информации за салдото',
            currency: 'Валута',
            balance: 'Салдо'
        },
        loginModal: {
            close: 'Затвори',
            username: 'Корисничко име',
            password: 'Лозинка',
            savePassword: 'Зачувај ја лозинката',
            loading: 'Се вчитува...',
            login: 'Најави се',
            lostLogin: 'Ја изгубивте најавата?'
        },

        myBets: {
            title: 'Мои Облози',
            filter: 'Филтер',
            tabs: {
                sports: 'Спортови',
                virtualSoccer: 'Виртуелен Фудбал',
                virtualGames: 'Виртуелни Игри'
            },
            betNumber: 'Бр.{id}',
            betStatus: {
                won: 'Добиен',
                lost: 'Изгубен',
                open: 'Во тек'
            },
            currency: 'ДЕН',
            amount: 'Износ',
            toReturn: 'За враќање',
            all: 'Сите'
        },
        accountBalance: {
            title: 'Состојба на Сметка',
            all: 'Сите',
            filter: 'Филтер',
            quickFilters: {
                today: 'Денес',
                yesterday: 'Вчера',
                december: 'Декември',
                november: 'Ноември'
            },
            table: {
                info: 'Инфо',
                totalBets: 'Вкупно Облози',
                winnings: 'Добивки',
                commission: 'Провизија',
                netReturn: 'Нето Поврат'
            }
        },
        usersList: {
            seniorsList: 'Листа на Сениори',
            managersList: 'Листа на Менаџери',
            agentsList: 'Листа на Агенти',
            usersList: 'Листа на Корисници',
            myBalance: 'Мој Баланс',
            balance: 'Баланс',
            balanceForEachUser: 'Баланс за секој корисник',
            negative: 'Негативно',
            positive: 'Позитивно',
            username: 'Корисничко име',
            errorFetchingData: 'Неуспешно вчитување на податоците за корисникот. Ве молиме обидете се повторно.'
        },
        newUser: {
            createNewUser: 'Креирај Нов Корисник',
            username: 'Корисничко име',
            password: 'Лозинка',
            firstName: 'Име (Опционално)',
            lastName: 'Презиме (Опционално)',
            email: 'Е-пошта',
            allCurrencies: 'Сите Валути',
            processing: 'Се обработува...',
            register: 'Регистрирај се',
            errors: {
                usernameRequired: 'Корисничкото име е задолжително',
                passwordRequired: 'Лозинката е задолжителна',
                emailRequired: 'Е-поштата е задолжителна',
                currencyRequired: 'Валутата е задолжителна',
                authTokenNotFound: 'Токенот за автентикација не е пронајден',
                failedToCreateUser: 'Неуспешно креирање на корисник'
            },
            userCreatedSuccessfully: 'Корисникот е успешно креиран:',
            currentUser: 'Тековен корисник:',
            currentUserWallets: 'Паричници на тековниот корисник:',
            availableCurrencies: 'Достапни валути:'
        },
        changePassword: {
            title: 'Промени лозинка',
            currentPassword: 'Тековна лозинка',
            newPassword: 'Нова лозинка',
            confirmPassword: 'Потврди лозинка',
            processing: 'Се обработува...',
            save: 'Зачувај',
            passwordMismatch: 'Новата лозинка и потврдата на лозинката не се совпаѓаат',
            failedToChange: 'Неуспешна промена на лозинката:',
            componentMounted: 'ChangePasswordView е монтиран, подготвен за преземање на податоци.',
            sportsFetched: 'Податоците за спортовите се успешно преземени.',
            failedToFetchSports: 'Неуспешно преземање на податоци за спортови:'
        },
        userDetailsPage: {
            goBack: 'Назад',
            title: 'Детали за корисникот',
            loading: 'Вчитување на детали за корисникот...',
            userInformation: 'Информации за корисникот',
            username: 'Корисничко име',
            firstName: 'Име',
            lastName: 'Презиме',
            email: 'Е-пошта',
            balanceInformation: 'Информации за салдото',
            currency: 'Валута',
            balance: 'Салдо',
            noUserData: 'Нема достапни податоци за корисникот.',
            transferBalance: 'Пренос на салдо {code}',
            amount: 'Износ',
            processing: 'Обработка...',
            save: 'Зачувај',
            errors: {
                authTokenNotFound: 'Токенот за автентикација не е пронајден',
                failedToFetchUserDetails: 'Неуспешно преземање на деталите за корисникот',
                errorFetchingUserDetails: 'Грешка при преземање на деталите за корисникот:',
                userNotFound: 'Корисникот не е пронајден',
                failedToLoadUserDetails: 'Неуспешно вчитување на деталите за корисникот. Ве молиме обидете се повторно.',
                enterValidAmount: 'Ве молиме внесете валиден износ',
                transferFailed: 'Преносот не успеа',
                transferError: 'Грешка при пренос:',
                failedToProcessTransfer: 'Неуспешна обработка на преносот. Ве молиме обидете се повторно.'
            }
        },
        withdrawal: {
            title: 'Барање за Повлекување',
            requestWithdrawal: 'Побарај Повлекување',
            currentBalance: 'Тековно Салдо',
            amount: 'Износ',
            submit: 'Поднеси',
            processing: 'Се обработува...',
            success: 'Барањето за повлекување е успешно поднесено',
            errors: {
                invalidAmount: 'Ве молиме внесете валиден износ',
                currencyRequired: 'Ве молиме изберете валута',
                authTokenNotFound: 'Токенот за автентикација не е пронајден',
                failedToSubmitRequest: 'Неуспешно поднесување на барањето за повлекување'
            }
        },
    },
    it: { // Italian (Italiano)
        header: {
            az: 'A-Z',
            inPlay: 'In Diretta',
            logoAlt: 'logo itbet365',
            myBets: 'Le Mie Scommesse',
            login: 'Accedi'
        },
        footer: {
            backToTop: 'Torna in Cima',
            footerTitle: 'itbet365 Sport',
            termsAndConditions: 'Termini e Condizioni',
            responsibleGambling: 'Gioco Responsabile',
            privacyPolicy: 'Informativa sulla Privacy',
            cookiesPolicy: 'Accedendo, continuando a utilizzare o navigando in questo sito, accetti che utilizzeremo determinati cookie del browser per migliorare la tua esperienza cliente con noi. Il nostro sito utilizza solo cookie che miglioreranno la tua esperienza con noi e non interferiranno con la tua privacy. Consulta la nostra Politica sui cookie per ulteriori informazioni.',
            responsibleGamblingMessage: 'Il gioco può creare dipendenza, gioca responsabilmente. Per informazioni sulle misure di supporto, visita la nostra pagina di Aiuto sul Gioco Responsabile.',
            copyrightNotice: '© 2016-2023 Tutti i diritti riservati.'
        },
        userAccountPanel: {
            account: 'Account',
            username: 'Nome utente',
            notAvailable: 'N/D',
            balance: 'Saldo in {currency}',
            logout: 'Esci',
            messages: 'Messaggi',
            usersList: 'Lista utenti',
            accountBalance: 'Saldo account',
            createNewUser: 'Crea Nuovo Utente',
            changePassword: 'Cambia password',
            infoAndRegulations: 'Informazioni e Regolamenti'
        },
        userDetails: {
            goBack: 'Indietro',
            title: 'Dettagli Utente',
            userInformation: 'Informazioni Utente',
            lastLogin: 'Ultimo Accesso',
            username: 'Nome utente',
            firstName: 'Nome',
            surname: 'Cognome',
            balanceInformation: 'Informazioni sul Saldo',
            currency: 'Valuta',
            balance: 'Saldo'
        },
        loginModal: {
            close: 'Chiudi',
            username: 'Nome utente',
            password: 'Password',
            savePassword: 'Salva Password',
            loading: 'Caricamento...',
            login: 'Accedi',
            lostLogin: 'Hai perso i dati di accesso?'
        },
        myBets: {
            title: 'Le Mie Scommesse',
            filter: 'Filtra',
            tabs: {
                sports: 'Sport',
                virtualSoccer: 'Calcio Virtuale',
                virtualGames: 'Giochi Virtuali'
            },
            betNumber: 'N°{id}',
            betStatus: {
                won: 'Vinta',
                lost: 'Persa',
                open: 'In sospeso'
            },
            currency: 'LEK',
            amount: 'Importo',
            toReturn: 'Da Restituire',
            all: 'Tutti'
        },
        accountBalance: {
            title: 'Saldo del Conto',
            all: 'Tutti',
            filter: 'Filtra',
            quickFilters: {
                today: 'Oggi',
                yesterday: 'Ieri',
                december: 'Dicembre',
                november: 'Novembre'
            },
            table: {
                info: 'Info',
                totalBets: 'Totale Scommesse',
                winnings: 'Vincite',
                commission: 'Commissione',
                netReturn: 'Ritorno Netto'
            }
        },
        usersList: {
            seniorsList: 'Lista dei Senior',
            managersList: 'Lista dei Manager',
            agentsList: 'Lista degli Agenti',
            usersList: 'Lista degli Utenti',
            myBalance: 'Il Mio Saldo',
            balance: 'Saldo',
            balanceForEachUser: 'Saldo per ogni utente',
            negative: 'Negativo',
            positive: 'Positivo',
            username: 'Nome utente',
            errorFetchingData: 'Impossibile caricare i dati dell\'utente. Per favore riprova.'
        },
        newUser: {
            createNewUser: 'Crea Nuovo Utente',
            username: 'Nome utente',
            password: 'Password',
            firstName: 'Nome (Opzionale)',
            lastName: 'Cognome (Opzionale)',
            email: 'Email',
            allCurrencies: 'Tutte le Valute',
            processing: 'Elaborazione in corso...',
            register: 'Registrati',
            errors: {
                usernameRequired: 'Il nome utente è obbligatorio',
                passwordRequired: 'La password è obbligatoria',
                emailRequired: 'L\'email è obbligatoria',
                currencyRequired: 'La valuta è obbligatoria',
                authTokenNotFound: 'Token di autenticazione non trovato',
                failedToCreateUser: 'Impossibile creare l\'utente'
            },
            userCreatedSuccessfully: 'Utente creato con successo:',
            currentUser: 'Utente corrente:',
            currentUserWallets: 'Portafogli dell\'utente corrente:',
            availableCurrencies: 'Valute disponibili:'
        },
        changePassword: {
            title: 'Cambia password',
            currentPassword: 'Password attuale',
            newPassword: 'Nuova password',
            confirmPassword: 'Conferma password',
            processing: 'Elaborazione in corso...',
            save: 'Salva',
            passwordMismatch: 'La nuova password e la conferma della password non corrispondono',
            failedToChange: 'Impossibile cambiare la password:',
            componentMounted: 'ChangePasswordView è montato, in procinto di recuperare i dati.',
            sportsFetched: 'Dati sportivi recuperati con successo.',
            failedToFetchSports: 'Impossibile recuperare i dati sportivi:'
        },
        userDetailsPage: {
            goBack: 'Indietro',
            title: 'Dettagli Utente',
            loading: 'Caricamento dettagli utente...',
            userInformation: 'Informazioni Utente',
            username: 'Nome utente',
            firstName: 'Nome',
            lastName: 'Cognome',
            email: 'Email',
            balanceInformation: 'Informazioni sul Saldo',
            currency: 'Valuta',
            balance: 'Saldo',
            noUserData: 'Nessun dato utente disponibile.',
            transferBalance: 'Trasferisci Saldo {code}',
            amount: 'Importo',
            processing: 'Elaborazione in corso...',
            save: 'Salva',
            errors: {
                authTokenNotFound: 'Token di autenticazione non trovato',
                failedToFetchUserDetails: 'Impossibile recuperare i dettagli dell\'utente',
                errorFetchingUserDetails: 'Errore nel recupero dei dettagli dell\'utente:',
                userNotFound: 'Utente non trovato',
                failedToLoadUserDetails: 'Impossibile caricare i dettagli dell\'utente. Si prega di riprovare.',
                enterValidAmount: 'Si prega di inserire un importo valido',
                transferFailed: 'Trasferimento fallito',
                transferError: 'Errore di trasferimento:',
                failedToProcessTransfer: 'Impossibile elaborare il trasferimento. Si prega di riprovare.'
            }
        },
        withdrawal: {
            title: 'Richiesta di Prelievo',
            requestWithdrawal: 'Richiedi Prelievo',
            currentBalance: 'Saldo Attuale',
            amount: 'Importo',
            submit: 'Invia',
            processing: 'Elaborazione in corso...',
            success: 'Richiesta di prelievo inviata con successo',
            errors: {
                invalidAmount: 'Inserisci un importo valido',
                currencyRequired: 'Seleziona una valuta',
                authTokenNotFound: 'Token di autenticazione non trovato',
                failedToSubmitRequest: 'Impossibile inviare la richiesta di prelievo'
            }
        },
    },
    de: { // German (Deutsch)
        header: {
            az: 'A-Z',
            inPlay: 'Live',
            logoAlt: 'itbet365 Logo',
            myBets: 'Meine Wetten',
            login: 'Anmelden'
        },
        footer: {
            backToTop: 'Nach oben',
            footerTitle: 'itbet365 Sport',
            termsAndConditions: 'Geschäftsbedingungen',
            responsibleGambling: 'Verantwortungsvolles Spielen',
            privacyPolicy: 'Datenschutzerklärung',
            cookiesPolicy: 'Durch den Zugriff, die weitere Nutzung oder Navigation auf dieser Website akzeptieren Sie, dass wir bestimmte Browser-Cookies verwenden werden, um Ihr Kundenerlebnis bei uns zu verbessern. Unsere Website verwendet nur Cookies, die Ihr Erlebnis bei uns verbessern und nicht in Ihre Privatsphäre eingreifen werden.',
            responsibleGamblingMessage: 'Glücksspiel kann süchtig machen, bitte spielen Sie verantwortungsvoll. Informationen zu Unterstützungsmaßnahmen finden Sie auf unserer Hilfeseite zum verantwortungsvollen Spielen.',
            copyrightNotice: '© 2016-2023 Alle Rechte vorbehalten.'
        },
        userAccountPanel: {
            account: 'Konto',
            username: 'Benutzername',
            notAvailable: 'N/V',
            balance: '{currency} Guthaben',
            logout: 'Abmelden',
            messages: 'Nachrichten',
            usersList: 'Benutzerliste',
            accountBalance: 'Kontostand',
            createNewUser: 'Neuen Benutzer erstellen',
            changePassword: 'Passwort ändern',
            infoAndRegulations: 'Info und Vorschriften'
        },
        userDetails: {
            goBack: 'Zurück',
            title: 'Benutzerdetails',
            userInformation: 'Benutzerinformationen',
            lastLogin: 'Letzter Login',
            username: 'Benutzername',
            firstName: 'Vorname',
            surname: 'Nachname',
            balanceInformation: 'Kontoinformationen',
            currency: 'Währung',
            balance: 'Kontostand'
        },
        loginModal: {
            close: 'Schließen',
            username: 'Benutzername',
            password: 'Passwort',
            savePassword: 'Passwort speichern',
            loading: 'Wird geladen...',
            login: 'Anmelden',
            lostLogin: 'Login vergessen?'
        },
        myBets: {
            title: 'Meine Wetten',
            filter: 'Filter',
            tabs: {
                sports: 'Sport',
                virtualSoccer: 'Virtueller Fußball',
                virtualGames: 'Virtuelle Spiele'
            },
            betNumber: 'Nr.{id}',
            betStatus: {
                won: 'Gewonnen',
                lost: 'Verloren',
                open: 'Ausstehend'
            },
            currency: 'LEK',
            amount: 'Betrag',
            toReturn: 'Rückgabe',
            all: 'Alle'
        },
        accountBalance: {
            title: 'Kontostand',
            all: 'Alle',
            filter: 'Filter',
            quickFilters: {
                today: 'Heute',
                yesterday: 'Gestern',
                december: 'Dezember',
                november: 'November'
            },
            table: {
                info: 'Info',
                totalBets: 'Gesamtwetten',
                winnings: 'Gewinne',
                commission: 'Provision',
                netReturn: 'Nettoertrag'
            }
        },
        usersList: {
            seniorsList: 'Seniorenliste',
            managersList: 'Managerliste',
            agentsList: 'Agentenliste',
            usersList: 'Benutzerliste',
            myBalance: 'Mein Guthaben',
            balance: 'Guthaben',
            balanceForEachUser: 'Guthaben für jeden Benutzer',
            negative: 'Negativ',
            positive: 'Positiv',
            username: 'Benutzername',
            errorFetchingData: 'Fehler beim Laden der Benutzerdaten. Bitte versuchen Sie es erneut.'
        },
        newUser: {
            createNewUser: 'Neuen Benutzer erstellen',
            username: 'Benutzername',
            password: 'Passwort',
            firstName: 'Vorname (Optional)',
            lastName: 'Nachname (Optional)',
            email: 'E-Mail',
            allCurrencies: 'Alle Währungen',
            processing: 'Verarbeitung...',
            register: 'Registrieren',
            errors: {
                usernameRequired: 'Benutzername ist erforderlich',
                passwordRequired: 'Passwort ist erforderlich',
                emailRequired: 'E-Mail ist erforderlich',
                currencyRequired: 'Währung ist erforderlich',
                authTokenNotFound: 'Authentifizierungstoken nicht gefunden',
                failedToCreateUser: 'Benutzer konnte nicht erstellt werden'
            },
            userCreatedSuccessfully: 'Benutzer erfolgreich erstellt:',
            currentUser: 'Aktueller Benutzer:',
            currentUserWallets: 'Geldbörsen des aktuellen Benutzers:',
            availableCurrencies: 'Verfügbare Währungen:'
        },
        changePassword: {
            title: 'Passwort ändern',
            currentPassword: 'Aktuelles Passwort',
            newPassword: 'Neues Passwort',
            confirmPassword: 'Passwort bestätigen',
            processing: 'Verarbeitung...',
            save: 'Speichern',
            passwordMismatch: 'Neues Passwort und Passwortbestätigung stimmen nicht überein',
            failedToChange: 'Passwort konnte nicht geändert werden:',
            componentMounted: 'ChangePasswordView ist montiert, kurz vor dem Abrufen von Daten.',
            sportsFetched: 'Sportdaten erfolgreich abgerufen.',
            failedToFetchSports: 'Fehler beim Abrufen der Sportdaten:'
        },
        userDetailsPage: {
            goBack: 'Zurück',
            title: 'Benutzerdetails',
            loading: 'Benutzerdetails werden geladen...',
            userInformation: 'Benutzerinformationen',
            username: 'Benutzername',
            firstName: 'Vorname',
            lastName: 'Nachname',
            email: 'E-Mail',
            balanceInformation: 'Kontoinformationen',
            currency: 'Währung',
            balance: 'Kontostand',
            noUserData: 'Keine Benutzerdaten verfügbar.',
            transferBalance: 'Guthaben übertragen {code}',
            amount: 'Betrag',
            processing: 'Verarbeitung...',
            save: 'Speichern',
            errors: {
                authTokenNotFound: 'Authentifizierungstoken nicht gefunden',
                failedToFetchUserDetails: 'Fehler beim Abrufen der Benutzerdetails',
                errorFetchingUserDetails: 'Fehler beim Abrufen der Benutzerdetails:',
                userNotFound: 'Benutzer nicht gefunden',
                failedToLoadUserDetails: 'Fehler beim Laden der Benutzerdetails. Bitte versuchen Sie es erneut.',
                enterValidAmount: 'Bitte geben Sie einen gültigen Betrag ein',
                transferFailed: 'Übertragung fehlgeschlagen',
                transferError: 'Übertragungsfehler:',
                failedToProcessTransfer: 'Fehler bei der Verarbeitung der Übertragung. Bitte versuchen Sie es erneut.'
            }
        },
        withdrawal: {
            title: 'Auszahlungsantrag',
            requestWithdrawal: 'Auszahlung beantragen',
            currentBalance: 'Aktuelles Guthaben',
            amount: 'Betrag',
            submit: 'Absenden',
            processing: 'Wird bearbeitet...',
            success: 'Auszahlungsantrag erfolgreich eingereicht',
            errors: {
                invalidAmount: 'Bitte geben Sie einen gültigen Betrag ein',
                currencyRequired: 'Bitte wählen Sie eine Währung aus',
                authTokenNotFound: 'Authentifizierungstoken nicht gefunden',
                failedToSubmitRequest: 'Fehler beim Einreichen des Auszahlungsantrags'
            }
        },
    },
    el: { // Greek (Ελληνικά)
        header: {
            az: 'Α-Ω',
            inPlay: 'Ζωντανά',
            logoAlt: 'λογότυπο itbet365',
            myBets: 'Τα Στοιχήματά μου',
            login: 'Σύνδεση'
        },
        footer: {
            backToTop: 'Επιστροφή στην κορυφή',
            footerTitle: 'itbet365 Sport',
            termsAndConditions: 'Όροι και Προϋποθέσεις',
            responsibleGambling: 'Υπεύθυνος Τζόγος',
            privacyPolicy: 'Πολιτική Απορρήτου',
            cookiesPolicy: 'Με την πρόσβαση, τη συνέχιση χρήσης ή την πλοήγηση σε αυτόν τον ιστότοπο, αποδέχεστε ότι θα χρησιμοποιήσουμε συγκεκριμένα cookies του προγράμματος περιήγησης για να βελτιώσουμε την εμπειρία σας ως πελάτης μαζί μας. Ο ιστότοπός μας χρησιμοποιεί μόνο cookies που θα βελτιώσουν την εμπειρία σας μαζί μας.',
            responsibleGamblingMessage: 'Ο τζόγος μπορεί να είναι εθιστικός, παρακαλούμε παίξτε υπεύθυνα. Για πληροφορίες σχετικά με τα μέτρα υποστήριξης, επισκεφθείτε τη σελίδα Βοήθειας Υπεύθυνου Τζόγου.',
            copyrightNotice: '© 2016-2023 Με επιφύλαξη παντός δικαιώματος.'
        },
        userAccountPanel: {
            account: 'Λογαριασμός',
            username: 'Όνομα χρήστη',
            notAvailable: 'Μ/Δ',
            balance: 'Υπόλοιπο σε {currency}',
            logout: 'Αποσύνδεση',
            messages: 'Μηνύματα',
            usersList: 'Λίστα χρηστών',
            accountBalance: 'Υπόλοιπο λογαριασμού',
            createNewUser: 'Δημιουργία Νέου Χρήστη',
            changePassword: 'Αλλαγή κωδικού',
            infoAndRegulations: 'Πληροφορίες και Κανονισμοί'
        },
        userDetails: {
            goBack: 'Επιστροφή',
            title: 'Λεπτομέρειες Χρήστη',
            userInformation: 'Πληροφορίες Χρήστη',
            lastLogin: 'Τελευταία Σύνδεση',
            username: 'Όνομα χρήστη',
            firstName: 'Όνομα',
            surname: 'Επώνυμο',
            balanceInformation: 'Πληροφορίες Υπολοίπου',
            currency: 'Νόμισμα',
            balance: 'Υπόλοιπο'
        },
        loginModal: {
            close: 'Κλείσιμο',
            username: 'Όνομα χρήστη',
            password: 'Κωδικός',
            savePassword: 'Αποθήκευση Κωδικού',
            loading: 'Φόρτωση...',
            login: 'Σύνδεση',
            lostLogin: 'Ξεχάσατε τα στοιχεία σύνδεσης;'
        },
        myBets: {
            title: 'Τα Στοιχήματά μου',
            filter: 'Φίλτρο',
            tabs: {
                sports: 'Αθλήματα',
                virtualSoccer: 'Εικονικό Ποδόσφαιρο',
                virtualGames: 'Εικονικά Παιχνίδια'
            },
            betNumber: 'Αρ.{id}',
            betStatus: {
                won: 'Κερδισμένο',
                lost: 'Χαμένο',
                open: 'Σε εκκρεμότητα'
            },
            currency: 'LEK',
            amount: 'Ποσό',
            toReturn: 'Επιστροφή',
            all: 'Όλα'
        },
        accountBalance: {
            title: 'Υπόλοιπο Λογαριασμού',
            all: 'Όλα',
            filter: 'Φίλτρο',
            quickFilters: {
                today: 'Σήμερα',
                yesterday: 'Χθες',
                december: 'Δεκέμβριος',
                november: 'Νοέμβριος'
            },
            table: {
                info: 'Πληροφορίες',
                totalBets: 'Σύνολο Στοιχημάτων',
                winnings: 'Κέρδη',
                commission: 'Προμήθεια',
                netReturn: 'Καθαρή Απόδοση'
            }
        },
        usersList: {
            seniorsList: 'Λίστα Ανώτερων',
            managersList: 'Λίστα Διευθυντών',
            agentsList: 'Λίστα Πρακτόρων',
            usersList: 'Λίστα Χρηστών',
            myBalance: 'Το Υπόλοιπό μου',
            balance: 'Υπόλοιπο',
            balanceForEachUser: 'Υπόλοιπο για κάθε χρήστη',
            negative: 'Αρνητικό',
            positive: 'Θετικό',
            username: 'Όνομα χρήστη',
            errorFetchingData: 'Αποτυχία φόρτωσης δεδομένων χρήστη. Παρακαλώ δοκιμάστε ξανά.'
        },
        newUser: {
            createNewUser: 'Δημιουργία Νέου Χρήστη',
            username: 'Όνομα χρήστη',
            password: 'Κωδικός πρόσβασης',
            firstName: 'Όνομα (Προαιρετικό)',
            lastName: 'Επώνυμο (Προαιρετικό)',
            email: 'Email',
            allCurrencies: 'Όλα τα Νομίσματα',
            processing: 'Επεξεργασία...',
            register: 'Εγγραφή',
            errors: {
                usernameRequired: 'Το όνομα χρήστη είναι υποχρεωτικό',
                passwordRequired: 'Ο κωδικός πρόσβασης είναι υποχρεωτικός',
                emailRequired: 'Το email είναι υποχρεωτικό',
                currencyRequired: 'Το νόμισμα είναι υποχρεωτικό',
                authTokenNotFound: 'Το διακριτικό ελέγχου ταυτότητας δεν βρέθηκε',
                failedToCreateUser: 'Αποτυχία δημιουργίας χρήστη'
            },
            userCreatedSuccessfully: 'Ο χρήστης δημιουργήθηκε με επιτυχία:',
            currentUser: 'Τρέχων χρήστης:',
            currentUserWallets: 'Πορτοφόλια τρέχοντος χρήστη:',
            availableCurrencies: 'Διαθέσιμα νομίσματα:'
        },
        changePassword: {
            title: 'Αλλαγή κωδικού πρόσβασης',
            currentPassword: 'Τρέχων κωδικός πρόσβασης',
            newPassword: 'Νέος κωδικός πρόσβασης',
            confirmPassword: 'Επιβεβαίωση κωδικού πρόσβασης',
            processing: 'Επεξεργασία...',
            save: 'Αποθήκευση',
            passwordMismatch: 'Ο νέος κωδικός πρόσβασης και η επιβεβαίωση κωδικού πρόσβασης δεν ταιριάζουν',
            failedToChange: 'Αποτυχία αλλαγής κωδικού πρόσβασης:',
            componentMounted: 'Το ChangePasswordView έχει φορτωθεί, έτοιμο για λήψη δεδομένων.',
            sportsFetched: 'Τα δεδομένα αθλημάτων ανακτήθηκαν με επιτυχία.',
            failedToFetchSports: 'Αποτυχία λήψης δεδομένων αθλημάτων:'
        },
        userDetailsPage: {
            goBack: 'Επιστροφή',
            title: 'Λεπτομέρειες Χρήστη',
            loading: 'Φόρτωση λεπτομερειών χρήστη...',
            userInformation: 'Πληροφορίες Χρήστη',
            username: 'Όνομα χρήστη',
            firstName: 'Όνομα',
            lastName: 'Επώνυμο',
            email: 'Email',
            balanceInformation: 'Πληροφορίες Υπολοίπου',
            currency: 'Νόμισμα',
            balance: 'Υπόλοιπο',
            noUserData: 'Δεν υπάρχουν διαθέσιμα δεδομένα χρήστη.',
            transferBalance: 'Μεταφορά Υπολοίπου {code}',
            amount: 'Ποσό',
            processing: 'Επεξεργασία...',
            save: 'Αποθήκευση',
            errors: {
                authTokenNotFound: 'Το διακριτικό ελέγχου ταυτότητας δεν βρέθηκε',
                failedToFetchUserDetails: 'Αποτυχία λήψης λεπτομερειών χρήστη',
                errorFetchingUserDetails: 'Σφάλμα κατά τη λήψη λεπτομερειών χρήστη:',
                userNotFound: 'Ο χρήστης δεν βρέθηκε',
                failedToLoadUserDetails: 'Αποτυχία φόρτωσης λεπτομερειών χρήστη. Παρακαλώ δοκιμάστε ξανά.',
                enterValidAmount: 'Παρακαλώ εισάγετε ένα έγκυρο ποσό',
                transferFailed: 'Η μεταφορά απέτυχε',
                transferError: 'Σφάλμα μεταφοράς:',
                failedToProcessTransfer: 'Αποτυχία επεξεργασίας μεταφοράς. Παρακαλώ δοκιμάστε ξανά.'
            }
        },
        withdrawal: {
            title: 'Αίτημα Ανάληψης',
            requestWithdrawal: 'Αίτηση Ανάληψης',
            currentBalance: 'Τρέχον Υπόλοιπο',
            amount: 'Ποσό',
            submit: 'Υποβολή',
            processing: 'Επεξεργασία...',
            success: 'Το αίτημα ανάληψης υποβλήθηκε με επιτυχία',
            errors: {
                invalidAmount: 'Παρακαλώ εισάγετε ένα έγκυρο ποσό',
                currencyRequired: 'Παρακαλώ επιλέξτε ένα νόμισμα',
                authTokenNotFound: 'Το διακριτικό ελέγχου ταυτότητας δεν βρέθηκε',
                failedToSubmitRequest: 'Αποτυχία υποβολής αιτήματος ανάληψης'
            }
        },
    },
    fr: { // French (Français)
        header: {
            az: 'A-Z',
            inPlay: 'En Direct',
            logoAlt: 'logo itbet365',
            myBets: 'Mes Paris',
            login: 'Connexion'
        },
        footer: {
            backToTop: 'Retour en haut',
            footerTitle: 'itbet365 Sport',
            termsAndConditions: 'Termes et conditions',
            responsibleGambling: 'Jeu responsable',
            privacyPolicy: 'Politique de confidentialité',
            cookiesPolicy: 'En accédant, en continuant à utiliser ou en naviguant sur ce site, vous acceptez que nous utilisions certains cookies de navigateur pour améliorer votre expérience client avec nous. Notre site utilise uniquement des cookies qui amélioreront votre expérience avec nous et n\'interféreront pas avec votre vie privée.',
            responsibleGamblingMessage: 'Le jeu peut être addictif, veuillez jouer de manière responsable. Pour plus d\'informations sur les mesures de soutien, veuillez consulter notre page d\'aide sur le jeu responsable.',
            copyrightNotice: '© 2016-2023 Tous droits réservés.'
        },
        userAccountPanel: {
            account: 'Compte',
            username: 'Nom d\'utilisateur',
            notAvailable: 'N/D',
            balance: 'Solde en {currency}',
            logout: 'Déconnexion',
            messages: 'Messages',
            usersList: 'Liste des utilisateurs',
            accountBalance: 'Solde du compte',
            createNewUser: 'Créer un nouvel utilisateur',
            changePassword: 'Changer le mot de passe',
            infoAndRegulations: 'Informations et règlements'
        },
        userDetails: {
            goBack: 'Retour',
            title: 'Détails de l\'utilisateur',
            userInformation: 'Informations de l\'utilisateur',
            lastLogin: 'Dernière connexion',
            username: 'Nom d\'utilisateur',
            firstName: 'Prénom',
            surname: 'Nom de famille',
            balanceInformation: 'Informations de solde',
            currency: 'Devise',
            balance: 'Solde'
        },
        loginModal: {
            close: 'Fermer',
            username: 'Nom d\'utilisateur',
            password: 'Mot de passe',
            savePassword: 'Enregistrer le mot de passe',
            loading: 'Chargement...',
            login: 'Connexion',
            lostLogin: 'Identifiants perdus ?'
        },
        myBets: {
            title: 'Mes Paris',
            filter: 'Filtrer',
            tabs: {
                sports: 'Sports',
                virtualSoccer: 'Football Virtuel',
                virtualGames: 'Jeux Virtuels'
            },
            betNumber: 'N°{id}',
            betStatus: {
                won: 'Gagné',
                lost: 'Perdu',
                open: 'En attente'
            },
            currency: 'LEK',
            amount: 'Montant',
            toReturn: 'À Retourner',
            all: 'Tous'
        },
        accountBalance: {
            title: 'Solde du Compte',
            all: 'Tous',
            filter: 'Filtrer',
            quickFilters: {
                today: 'Aujourd\'hui',
                yesterday: 'Hier',
                december: 'Décembre',
                november: 'Novembre'
            },
            table: {
                info: 'Info',
                totalBets: 'Total des Paris',
                winnings: 'Gains',
                commission: 'Commission',
                netReturn: 'Retour Net'
            }
        },
        usersList: {
            seniorsList: 'Liste des Seniors',
            managersList: 'Liste des Managers',
            agentsList: 'Liste des Agents',
            usersList: 'Liste des Utilisateurs',
            myBalance: 'Mon Solde',
            balance: 'Solde',
            balanceForEachUser: 'Solde pour chaque utilisateur',
            negative: 'Négatif',
            positive: 'Positif',
            username: 'Nom d\'utilisateur',
            errorFetchingData: 'Échec du chargement des données utilisateur. Veuillez réessayer.'
        },
        newUser: {
            createNewUser: 'Créer un Nouvel Utilisateur',
            username: 'Nom d\'utilisateur',
            password: 'Mot de passe',
            firstName: 'Prénom (Optionnel)',
            lastName: 'Nom (Optionnel)',
            email: 'Email',
            allCurrencies: 'Toutes les Devises',
            processing: 'Traitement en cours...',
            register: 'S\'inscrire',
            errors: {
                usernameRequired: 'Le nom d\'utilisateur est requis',
                passwordRequired: 'Le mot de passe est requis',
                emailRequired: 'L\'email est requis',
                currencyRequired: 'La devise est requise',
                authTokenNotFound: 'Jeton d\'authentification non trouvé',
                failedToCreateUser: 'Échec de la création de l\'utilisateur'
            },
            userCreatedSuccessfully: 'Utilisateur créé avec succès :',
            currentUser: 'Utilisateur actuel :',
            currentUserWallets: 'Portefeuilles de l\'utilisateur actuel :',
            availableCurrencies: 'Devises disponibles :'
        },
        changePassword: {
            title: 'Changer le mot de passe',
            currentPassword: 'Mot de passe actuel',
            newPassword: 'Nouveau mot de passe',
            confirmPassword: 'Confirmer le mot de passe',
            processing: 'Traitement en cours...',
            save: 'Enregistrer',
            passwordMismatch: 'Le nouveau mot de passe et la confirmation ne correspondent pas',
            failedToChange: 'Échec du changement de mot de passe :',
            componentMounted: 'ChangePasswordView est monté, sur le point de récupérer les données.',
            sportsFetched: 'Données sportives récupérées avec succès.',
            failedToFetchSports: 'Échec de la récupération des données sportives :'
        },
        userDetailsPage: {
            goBack: 'Retour',
            title: 'Détails de l\'utilisateur',
            loading: 'Chargement des détails de l\'utilisateur...',
            userInformation: 'Informations de l\'utilisateur',
            username: 'Nom d\'utilisateur',
            firstName: 'Prénom',
            lastName: 'Nom',
            email: 'Email',
            balanceInformation: 'Informations de solde',
            currency: 'Devise',
            balance: 'Solde',
            noUserData: 'Aucune donnée utilisateur disponible.',
            transferBalance: 'Transférer le solde {code}',
            amount: 'Montant',
            processing: 'Traitement en cours...',
            save: 'Enregistrer',
            errors: {
                authTokenNotFound: 'Jeton d\'authentification non trouvé',
                failedToFetchUserDetails: 'Échec de la récupération des détails de l\'utilisateur',
                errorFetchingUserDetails: 'Erreur lors de la récupération des détails de l\'utilisateur :',
                userNotFound: 'Utilisateur non trouvé',
                failedToLoadUserDetails: 'Échec du chargement des détails de l\'utilisateur. Veuillez réessayer.',
                enterValidAmount: 'Veuillez entrer un montant valide',
                transferFailed: 'Le transfert a échoué',
                transferError: 'Erreur de transfert :',
                failedToProcessTransfer: 'Échec du traitement du transfert. Veuillez réessayer.'
            }
        },
        withdrawal: {
            title: 'Demande de Retrait',
            requestWithdrawal: 'Demander un Retrait',
            currentBalance: 'Solde Actuel',
            amount: 'Montant',
            submit: 'Soumettre',
            processing: 'Traitement en cours...',
            success: 'Demande de retrait soumise avec succès',
            errors: {
                invalidAmount: 'Veuillez entrer un montant valide',
                currencyRequired: 'Veuillez sélectionner une devise',
                authTokenNotFound: 'Jeton d\'authentification non trouvé',
                failedToSubmitRequest: 'Échec de la soumission de la demande de retrait'
            }
        },
    },
    es: { // Spanish (Español)
        header: {
            az: 'A-Z',
            inPlay: 'En Juego',
            logoAlt: 'logo de itbet365',
            myBets: 'Mis Apuestas',
            login: 'Iniciar Sesión'
        },
        footer: {
            backToTop: 'Volver arriba',
            footerTitle: 'itbet365 Deporte',
            termsAndConditions: 'Términos y condiciones',
            responsibleGambling: 'Juego responsable',
            privacyPolicy: 'Política de privacidad',
            cookiesPolicy: 'Al acceder, continuar usando o navegar por este sitio, usted acepta que utilizaremos ciertas cookies del navegador para mejorar su experiencia como cliente. Nuestro sitio solo utiliza cookies que mejorarán su experiencia con nosotros y no interferirán con su privacidad.',
            responsibleGamblingMessage: 'El juego puede ser adictivo, por favor juegue con responsabilidad. Para obtener información sobre medidas de apoyo, visite nuestra página de Ayuda de Juego Responsable.',
            copyrightNotice: '© 2016-2023 Todos los derechos reservados.'
        },
        userAccountPanel: {
            account: 'Cuenta',
            username: 'Nombre de usuario',
            notAvailable: 'N/D',
            balance: 'Saldo en {currency}',
            logout: 'Cerrar sesión',
            messages: 'Mensajes',
            usersList: 'Lista de usuarios',
            accountBalance: 'Saldo de la cuenta',
            createNewUser: 'Crear nuevo usuario',
            changePassword: 'Cambiar contraseña',
            infoAndRegulations: 'Información y regulaciones'
        },
        userDetails: {
            goBack: 'Volver',
            title: 'Detalles del Usuario',
            userInformation: 'Información del Usuario',
            lastLogin: 'Último Inicio de Sesión',
            username: 'Nombre de usuario',
            firstName: 'Nombre',
            surname: 'Apellido',
            balanceInformation: 'Información de Saldo',
            currency: 'Moneda',
            balance: 'Saldo'
        },
        loginModal: {
            close: 'Cerrar',
            username: 'Nombre de usuario',
            password: 'Contraseña',
            savePassword: 'Guardar Contraseña',
            loading: 'Cargando...',
            login: 'Iniciar sesión',
            lostLogin: '¿Perdiste tus datos de acceso?'
        },
        myBets: {
            title: 'Mis Apuestas',
            filter: 'Filtrar',
            tabs: {
                sports: 'Deportes',
                virtualSoccer: 'Fútbol Virtual',
                virtualGames: 'Juegos Virtuales'
            },
            betNumber: 'N°{id}',
            betStatus: {
                won: 'Ganada',
                lost: 'Perdida',
                open: 'Pendiente'
            },
            currency: 'LEK',
            amount: 'Cantidad',
            toReturn: 'A Devolver',
            all: 'Todos'
        },
        accountBalance: {
            title: 'Saldo de la Cuenta',
            all: 'Todos',
            filter: 'Filtrar',
            quickFilters: {
                today: 'Hoy',
                yesterday: 'Ayer',
                december: 'Diciembre',
                november: 'Noviembre'
            },
            table: {
                info: 'Info',
                totalBets: 'Total de Apuestas',
                winnings: 'Ganancias',
                commission: 'Comisión',
                netReturn: 'Retorno Neto'
            }
        },
        usersList: {
            seniorsList: 'Lista de Seniors',
            managersList: 'Lista de Gerentes',
            agentsList: 'Lista de Agentes',
            usersList: 'Lista de Usuarios',
            myBalance: 'Mi Saldo',
            balance: 'Saldo',
            balanceForEachUser: 'Saldo para cada usuario',
            negative: 'Negativo',
            positive: 'Positivo',
            username: 'Nombre de usuario',
            errorFetchingData: 'Error al cargar los datos del usuario. Por favor, inténtelo de nuevo.'
        },
        newUser: {
            createNewUser: 'Crear Nuevo Usuario',
            username: 'Nombre de usuario',
            password: 'Contraseña',
            firstName: 'Nombre (Opcional)',
            lastName: 'Apellido (Opcional)',
            email: 'Correo electrónico',
            allCurrencies: 'Todas las Monedas',
            processing: 'Procesando...',
            register: 'Registrarse',
            errors: {
                usernameRequired: 'El nombre de usuario es obligatorio',
                passwordRequired: 'La contraseña es obligatoria',
                emailRequired: 'El correo electrónico es obligatorio',
                currencyRequired: 'La moneda es obligatoria',
                authTokenNotFound: 'No se encontró el token de autenticación',
                failedToCreateUser: 'Error al crear el usuario'
            },
            userCreatedSuccessfully: 'Usuario creado con éxito:',
            currentUser: 'Usuario actual:',
            currentUserWallets: 'Carteras del usuario actual:',
            availableCurrencies: 'Monedas disponibles:'
        },
        changePassword: {
            title: 'Cambiar contraseña',
            currentPassword: 'Contraseña actual',
            newPassword: 'Nueva contraseña',
            confirmPassword: 'Confirmar contraseña',
            processing: 'Procesando...',
            save: 'Guardar',
            passwordMismatch: 'La nueva contraseña y la confirmación de la contraseña no coinciden',
            failedToChange: 'Error al cambiar la contraseña:',
            componentMounted: 'ChangePasswordView está montado, a punto de obtener datos.',
            sportsFetched: 'Datos de deportes obtenidos con éxito.',
            failedToFetchSports: 'Error al obtener datos de deportes:'
        },
        userDetailsPage: {
            goBack: 'Volver',
            title: 'Detalles del Usuario',
            loading: 'Cargando detalles del usuario...',
            userInformation: 'Información del Usuario',
            username: 'Nombre de usuario',
            firstName: 'Nombre',
            lastName: 'Apellido',
            email: 'Correo electrónico',
            balanceInformation: 'Información de Saldo',
            currency: 'Moneda',
            balance: 'Saldo',
            noUserData: 'No hay datos de usuario disponibles.',
            transferBalance: 'Transferir Saldo {code}',
            amount: 'Cantidad',
            processing: 'Procesando...',
            save: 'Guardar',
            errors: {
                authTokenNotFound: 'Token de autenticación no encontrado',
                failedToFetchUserDetails: 'Error al obtener los detalles del usuario',
                errorFetchingUserDetails: 'Error al obtener los detalles del usuario:',
                userNotFound: 'Usuario no encontrado',
                failedToLoadUserDetails: 'Error al cargar los detalles del usuario. Por favor, inténtelo de nuevo.',
                enterValidAmount: 'Por favor, introduzca una cantidad válida',
                transferFailed: 'La transferencia falló',
                transferError: 'Error en la transferencia:',
                failedToProcessTransfer: 'Error al procesar la transferencia. Por favor, inténtelo de nuevo.'
            }
        }
    },
    withdrawal: {
        title: 'Solicitud de Retiro',
        requestWithdrawal: 'Solicitar Retiro',
        currentBalance: 'Saldo Actual',
        amount: 'Cantidad',
        submit: 'Enviar',
        processing: 'Procesando...',
        success: 'Solicitud de retiro enviada con éxito',
        errors: {
            invalidAmount: 'Por favor, ingrese una cantidad válida',
            currencyRequired: 'Por favor, seleccione una moneda',
            authTokenNotFound: 'Token de autenticación no encontrado',
            failedToSubmitRequest: 'Error al enviar la solicitud de retiro'
        }
    },
};

