<template>
  <div class="min-h-screen bg-gray-100">
    <MainLayout :sports="sports">
      <div class="bg-custom-black py-8 px-4">
        <div class="w-full max-w-md mx-auto">
          <h2 class="text-2xl font-bold text-white mb-6 text-center">
            {{ t('newUser.createNewUser') }}
          </h2>
          <form @submit.prevent="handleSubmit" class="space-y-4" autocomplete="off">
            <!-- Username & Password group -->
            <div class="bg-white rounded-lg overflow-hidden">
              <input
                v-model="formData.username"
                type="text"
                :placeholder="t('newUser.username')"
                class="w-full p-4 border-b border-gray-200 text-bg-custom-black placeholder-gray-500 focus:outline-none"
                required
                autocomplete="off"
              />
              <p v-if="errors.username" class="text-red-500 text-sm mt-1">{{ errors.username }}</p>
              <div class="relative">
                <input
                  v-model="formData.password"
                  :type="showPassword ? 'text' : 'password'"
                  :placeholder="t('newUser.password')"
                  class="w-full p-4 pr-10 text-bg-custom-black placeholder-gray-500 focus:outline-none"
                  required
                  autocomplete="new-password"
                />
                <button 
                  type="button" 
                  @click="togglePasswordVisibility" 
                  class="absolute inset-y-0 right-0 pr-3 flex items-center"
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke-width="1.5" 
                    stroke="currentColor" 
                    class="w-6 h-6 text-gray-500"
                  >
                    <path 
                      stroke-linecap="round" 
                      stroke-linejoin="round" 
                      :d="showPassword 
                        ? 'M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88' 
                        : 'M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z M15 12a3 3 0 11-6 0 3 3 0 016 0z'"
                    />
                  </svg>
                </button>
              </div>
              <p v-if="errors.password" class="text-red-500 text-sm mt-1">{{ errors.password }}</p>
            </div>

            <!-- First Name & Last Name group -->
            <div class="bg-white rounded-lg overflow-hidden">
              <input
                v-model="formData.first_name"
                type="text"
                :placeholder="t('newUser.firstName')"
                class="w-full p-4 border-b border-gray-200 text-bg-custom-black placeholder-gray-500 focus:outline-none"
              />
              <input
                v-model="formData.last_name"
                type="text"
                :placeholder="t('newUser.lastName')"
                class="w-full p-4 text-bg-custom-black placeholder-gray-500 focus:outline-none"
              />
            </div>

            <!-- Email -->
            <div class="bg-white rounded-lg overflow-hidden">
              <input
                v-model="formData.email"
                type="email"
                :placeholder="t('newUser.email')"
                class="w-full p-4 text-bg-custom-black placeholder-gray-500 focus:outline-none"
                required
              />
              <p v-if="errors.email" class="text-red-500 text-sm mt-1">{{ errors.email }}</p>
            </div>

            <!-- Max Bet & Min Bet fields (only for agents) -->
            <div v-if="currentUser.role === 'agent'" class="bg-white rounded-lg overflow-hidden">
             
              <input
                v-model="formData.min_bet"
                type="number"
                :placeholder="t('newUser.minBet')"
                class="w-full p-4 text-bg-custom-black placeholder-gray-500 focus:outline-none"
                required
              />

               <input
                v-model="formData.max_bet"
                type="number"
                :placeholder="t('newUser.maxBet')"
                class="w-full p-4 border-b border-gray-200 text-bg-custom-black placeholder-gray-500 focus:outline-none"
                required
              />

              <input
                v-model="formData.max_bet_profit"
                type="number"
                :placeholder="t('newUser.maxBetProfit')"
                class="w-full p-4 text-bg-custom-black placeholder-gray-500 focus:outline-none"
                required
              />
            </div>

            <!-- Currency Dropdown -->
            <div class="relative bg-white rounded-lg overflow-hidden">
              <select
                v-model="selectedCurrency"
                class="w-full p-4 text-bg-custom-black appearance-none focus:outline-none"
                required
              >
                <option v-if="currentUser.role !== 'agent'" value="all">{{ t('newUser.allCurrencies') }}</option>
                <option
                  v-for="wallet in currentUserWallets"
                  :key="wallet.currency.id"
                  :value="wallet.currency.id"
                >
                  {{ wallet.currency.name }} ({{ wallet.currency.code }})
                </option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>
              <p v-if="errors.currency" class="text-red-500 text-sm mt-1">{{ errors.currency }}</p>
            </div>

            <!-- Submit Button -->
            <button
              type="submit"
              class="w-full bg-custom-bg-green text-white p-4 rounded-lg hover:bg-custom-green transition-colors duration-200"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? t('newUser.processing') : t('newUser.register') }}
            </button>
          </form>
        </div>
      </div>
    </MainLayout>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import MainLayout from '../layouts/MainLayout.vue'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

const formData = ref({
  username: '',
  password: '',
  first_name: '',
  last_name: '',
  email: '',
  max_bet: 0,
  min_bet: 0,
  max_bet_profit: 0
})

const errors = ref({})

const isSubmitting = ref(false)
const showPassword = ref(false)
const selectedCurrency = ref('all')  // Set default value to 'all'

const sports = computed(() => store.state.sports || [])
const currentUser = computed(() => store.getters.user || {})
const currentUserWallets = computed(() => currentUser.value.wallets || [])

const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value
}

const validateForm = () => {
  errors.value = {}
  if (!formData.value.username) errors.value.username = t('newUser.errors.usernameRequired')
  if (!formData.value.password) errors.value.password = t('newUser.errors.passwordRequired')
  if (!formData.value.email) errors.value.email = t('newUser.errors.emailRequired')
  if (!selectedCurrency.value) errors.value.currency = t('newUser.errors.currencyRequired')
  if (currentUser.value.role === 'agent') {
    if (!formData.value.max_bet) errors.value.max_bet = t('newUser.errors.maxBetRequired')
    if (!formData.value.min_bet) errors.value.min_bet = t('newUser.errors.minBetRequired')
    if (!formData.value.max_bet_profit) errors.value.max_bet_profit = t('newUser.errors.maxBetProfitRequired')
  }
  return Object.keys(errors.value).length === 0
}

const handleSubmit = async () => {
  if (!validateForm()) {
    return
  }
  try {
    isSubmitting.value = true
    const payload = {
      ...formData.value,
      currency_ids: selectedCurrency.value === 'all'
        ? currentUserWallets.value.map(wallet => wallet.currency.id)
        : [selectedCurrency.value],
      ...(currentUser.value.role === 'agent' && {
        max_bet: parseFloat(formData.value.max_bet),
        min_bet: parseFloat(formData.value.min_bet),
        max_bet_profit: parseFloat(formData.value.max_bet_profit)
      })
    }

    const authData = JSON.parse(localStorage.getItem('forbet_auth') || '{}')
    const token = authData.accessToken

    console.log('payload:', payload)

    if (!token) {
      throw new Error(t('newUser.errors.authTokenNotFound'))
    }

    const response = await fetch('https://api.newbet365.org/api/user/create', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(payload)
    })

    if (!response.ok) {
      throw new Error(t('newUser.errors.failedToCreateUser'))
    }

    const data = await response.json()
    console.log(t('newUser.userCreatedSuccessfully'), data)

    // Reset form and redirect
    formData.value = { username: '', password: '', first_name: '', last_name: '', email: '', max_bet: 0, min_bet: 0 , max_bet_profit: 0}
    selectedCurrency.value = 'all'  // Reset to 'all' after submission
    await store.dispatch("fetchUser");
    router.push('/users')
  } catch (error) {
    console.error(t('newUser.errors.failedToCreateUser'), error)
    // Handle error (e.g., show error message to user)
  } finally {
    isSubmitting.value = false
  }
}

onMounted(() => {
  if (currentUser.value.role === 'agent' && currentUserWallets.value.length > 0) {
    selectedCurrency.value = currentUserWallets.value[0].currency.id
  } else {
    selectedCurrency.value = 'all'
  }
})

// Log current user and wallet data for debugging
console.log(t('newUser.currentUser'), currentUser.value)
console.log(t('newUser.currentUserWallets'), currentUserWallets.value)
console.log(t('newUser.availableCurrencies'), currentUserWallets.value.map(wallet => wallet.currency))
</script>

<style scoped>
select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23666666'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}
</style>

