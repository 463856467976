<template>
  <div class="max-w-full mx-auto bg-custom-bg-4 text-custom-black">
    <!-- Header -->
    <div class="bg-custom-bg-green flex justify-between items-center p-2">
      <div class="font-bold">
        <span class="text-white">LIVE</span>
        <span class="text-yellow-300">IN-PLAY</span>
      </div>
      <div class="text-sm text-custom-green">
        <span>{{ totalMatches }}</span> Events <i class="ri-arrow-right-s-line"></i>
      </div>
    </div>
  
    <!-- Sport header -->
    <div class="bg-custom-bg-3 flex items-center text-sm">
      <div class="w-1/2 sm:w-2/3 p-1">Football</div>
      <div class="w-1/2 sm:w-1/3 grid grid-cols-3 text-center">
        <span class="p-1 border-l border-custom-bg-3">1</span>
        <span class="p-1 border-l border-custom-bg-3">X</span>
        <span class="p-1 border-l border-custom-bg-3">2</span>
      </div>
    </div>
  
    <!-- Live matches -->
    <div v-for="league in liveLeagues" :key="league.id">
      <div v-for="match in league.matches" :key="match.id" 
           class="flex items-stretch border-b border-custom-bg-3 text-white hover:bg-custom-bg-4 cursor-pointer"
           @click="navigateToMatchDetail(match.id)">
        <div class="w-1/2 sm:w-2/3 px-1">
          <div class="flex justify-between">
            <div class="pr-2">
              <div class="text-sm sm:text-base">{{ match.homeTeamName }}</div>
              <div class="text-sm sm:text-base">{{ match.awayTeamName }}</div>
            </div>
            <div class="text-xs sm:text-sm font-semibold text-custom-yellow">
              <div>{{ match.homeTeamScore }}</div>
              <div>{{ match.awayTeamScore }}</div>
            </div>
          </div>
          <div class="text-xs sm:text-sm text-custom-white mt-1">{{ formatMatchTime(match.timer) }}</div>
        </div>
        <div class="w-1/2 sm:w-1/3 grid grid-cols-3 h-full">
          <div v-for="(odd, index) in getMatchOdds(match)" :key="index" 
               class="relative border-l border-custom-bg-3 cursor-pointer transition-colors duration-200"
               :class="{
                 'bg-custom-black': !isOddSelected(match.id, odd.id),
                 'bg-white': isOddSelected(match.id, odd.id) && !isOddSuspended(odd)
               }"
               @click.stop="!isOddSuspended(odd) && toggleOddSelection(match.id, odd.id, odd, match)">
            <div class="absolute inset-0 flex items-center justify-center">
              <template v-if="!isOddSuspended(odd)">
                <span 
                  class="text-sm sm:text-base font-semibold"
                  :class="isOddSelected(match.id, odd.id) ? 'text-custom-green' : 'text-yellow-300'"
                >
                  {{ formatOdds(odd.odds) }}
                </span>
              </template>
              <template v-else>
                <i class="ri-lock-fill text-custom-white text-sm sm:text-base"></i>
              </template>
            </div>
            <div style="padding-bottom: 100%;"></div>
          </div>
        </div>
      </div>
    </div>
  
    <!-- Show All Events -->
    <div class="bg-custom-bg-4 flex justify-between text-custom-white items-center p-2 cursor-pointer hover:bg-custom-bg-3">
      <span class="text-sm">Show All Events</span>
      <span class="text-sm">{{ totalMatches }} Events</span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

const store = useStore();
const router = useRouter();
const liveLeagues = ref([]);
const currentLiveSportId = computed(() => store.state.currentLiveSportId);
const selectedOdds = ref({});

const totalMatches = computed(() => {
  return liveLeagues.value.reduce((total, league) => total + league.matches.length, 0);
});

const fetchLiveData = async (sportId) => {
  try {
    const response = await fetch(`https://api.newbet365.org/api/feed/live/${sportId}`);
    const data = await response.json();
    liveLeagues.value = data.data;
    localStorage.setItem(`liveData_${sportId}`, JSON.stringify(data));
  } catch (error) {
    console.error("Error fetching live data:", error);
  }
};

const formatMatchTime = (timer) => {
  if (timer && timer.isPlaying) {
    return `${timer.currentTime}'`;
  }
  return "Not started";
};

const getMatchOdds = (match) => {
  const marketKey = 'full_time_result';
  const selectedMarketData = match.markets.find(m => m.market.key === marketKey);

  if (!selectedMarketData || !selectedMarketData.odds) return [];

  return selectedMarketData.odds.sort((a, b) => {
    const order = ["1", "X", "2"];
    return order.indexOf(a.name) - order.indexOf(b.name);
  });
};

const isOddSuspended = (odd) => {
  return !odd || odd.is_suspended || odd.odds === null;
};

const formatOdds = (odds) => {
  return Number(odds).toFixed(2);
};

const navigateToMatchDetail = (matchId) => {
  router.push({ name: 'LiveDetail', params: { matchId: matchId } });
};

const toggleOddSelection = (matchId, oddId, odd, match) => {
  const marketId = odd.market_id;

  store.dispatch('betslip/addOrRemoveSelection', {
    matchId: matchId,
    oddId: oddId,
    matchName: `${match.homeTeamName} vs ${match.awayTeamName}`,
    market: odd.name,
    odds: odd.odds,
    marketId: marketId
  });

  // Update local state
  const newSelectedOdds = { ...selectedOdds.value };
  if (newSelectedOdds[matchId] === oddId) {
    delete newSelectedOdds[matchId];
  } else {
    newSelectedOdds[matchId] = oddId;
  }
  selectedOdds.value = newSelectedOdds;

  // Toggle betslip visibility
  const hasSelections = store.getters['betslip/selectionCount'] > 0;
  store.dispatch('betslip/toggleVisibility', hasSelections);
};

const isOddSelected = (matchId, oddId) => {
  return selectedOdds.value[matchId] === oddId;
};

const updateSelectedOddsFromBetslip = () => {
  const storedSelections = store.state.betslip.selections;
  const newSelectedOdds = {};
  storedSelections.forEach(selection => {
    newSelectedOdds[selection.matchId] = selection.oddId;
  });
  selectedOdds.value = newSelectedOdds;
};

onMounted(() => {
  fetchLiveData(currentLiveSportId.value);
  updateSelectedOddsFromBetslip();
  const pollInterval = setInterval(() => fetchLiveData(currentLiveSportId.value), 2000); // Poll every 30 seconds

  onUnmounted(() => {
    clearInterval(pollInterval);
  });
});

watch(
  () => store.state.betslip.selections,
  () => {
    updateSelectedOddsFromBetslip();
  },
  { deep: true }
);
</script>