<template>
  <div class="min-h-screen bg-gray-100">
    <template v-if="isLoading">
      <div class="p-4 max-w-7xl mx-auto">
        <SimpleSpinner />
      </div>
    </template>
    <template v-else>
      <MainLayout :sports="sports">
        <div v-if="error" class="max-w-full mx-auto text-white bg-red-600 p-4">
          {{ error }}
        </div>
        <div v-else-if="match" class="max-w-full mx-auto text-white">
          <LiveMatchTracker v-if="match" :match="match" />

          <!-- Market Categories -->
          <div class="bg-custom-bg-4 p-1 overflow-x-auto whitespace-nowrap">
            <button
              class="px-3 py-1 text-sm font-medium text-custom-white mr-2"
            >
              <i class="ri-star-line mr-1"></i>
            </button>
            <button
              v-for="category in marketCategories"
              :key="category"
              class="px-3 py-1 text-sm font-medium text-custom-white hover:bg-custom-bg-3 mr-2"
              :class="{ 'bg-custom-bg-3': selectedCategory === category }"
              @click="selectCategory(category)"
            >
              {{ category === "all" ? "All" : category }}
            </button>
          </div>

          <!-- Search Bar -->
          <div class="bg-custom-bg-3 p-0">
            <div class="relative">
              <input
                type="text"
                placeholder="Search"
                class="w-full bg-custom-black text-custom-white pl-10 pr-4 py-2 focus:outline-none focus:ring-0"
                v-model="searchQuery"
              />
              <i
                class="ri-search-line absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              ></i>
            </div>
          </div>

          <!-- Markets Display -->
          <div class="bg-custom-bg-4 w-full">
            <div
              v-for="market in filteredMarkets"
              :key="market.id"
              class="border-b border-custom-bg-3"
            >
              <div class="bg-custom-bg-green px-1 py-2 flex items-center">
                <i class="ri-star-line mr-1"></i>
                <span class="font-medium">{{ market.name }}</span>
              </div>
              <div class="px-0">
                <component
                  :is="getLayoutComponent(market.layout)"
                  :market="market"
                  :odds="getOddsArray(market.odds)"
                  :toggleOddSelection="toggleOddSelection"
                  :selectedOdds="selectedOdds"
                  :matchId="match.id"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="max-w-full mx-auto text-white bg-yellow-600 p-4">
          No match data available.
        </div>
      </MainLayout>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, h, watch, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import MainLayout from "../layouts/MainLayout.vue";
import SimpleSpinner from "../components/loaders/SimpleSpinner.vue";
import LiveMatchTracker from "../components/LiveMatchTracker.vue";

const store = useStore();
const route = useRoute();

const match = computed(() => store.state.selectedMatch ? store.state.selectedMatch.data : null);
const sports = computed(() => store.state.sports || []);
const selectedCategory = ref("all");
const searchQuery = ref("");
const error = ref(null);
const isLoading = computed(() => store.state.isLoading);
const selectedOdds = ref({});

let pollInterval;

onMounted(async () => {
  const matchId = route.params.matchId;
  if (matchId) {
    await fetchMatchDetailPageData(matchId);
    updateSelectedOddsFromBetslip();
    
    // Set up polling every 30 seconds
    pollInterval = setInterval(() => {
      fetchMatchDetailPageData(matchId);
    }, 2000);
  } else {
    error.value = "No match ID provided in the URL";
  }
});

onUnmounted(() => {
  // Clear the interval when the component is unmounted
  if (pollInterval) {
    clearInterval(pollInterval);
  }
});

watch(
  () => store.state.betslip.selections,
  () => {
    updateSelectedOddsFromBetslip();
  },
  { deep: true }
);

const updateSelectedOddsFromBetslip = () => {
  const storedSelections = store.state.betslip.selections;
  const newSelectedOdds = {};

  storedSelections.forEach((selection) => {
    if (selection.matchId === match.value?.id) {
      if (!newSelectedOdds[selection.matchId]) {
        newSelectedOdds[selection.matchId] = {};
      }
      newSelectedOdds[selection.matchId][selection.marketId] = selection.oddId;
    }
  });

  selectedOdds.value = newSelectedOdds;
};

const fetchMatchDetailPageData = async (matchId) => {
  try {
    await store.dispatch("fetchMatchDetailPageData", matchId);
    if (match.value && match.value.markets) {
      selectedCategory.value = "all";
    }
  } catch (err) {
    console.error("Error fetching match details:", err);
    error.value = "Failed to fetch match details. Please try again.";
  }
};


const marketCategories = computed(() => {
  if (!match.value || !match.value.markets) return [];
  return ["all", ...Object.keys(match.value.markets)];
});

const filteredMarkets = computed(() => {
  if (!match.value || !match.value.markets) return [];
  let allMarkets = [];
  if (selectedCategory.value === "all") {
    Object.values(match.value.markets).forEach((categoryMarkets) => {
      allMarkets = allMarkets.concat(categoryMarkets);
    });
  } else {
    allMarkets = match.value.markets[selectedCategory.value] || [];
  }
  return allMarkets.filter((market) =>
    market.name.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});

const selectCategory = (category) => {
  selectedCategory.value = category;
};

const getOddsArray = (odds) => {
  if (Array.isArray(odds)) {
    return odds;
  } else if (typeof odds === "object") {
    return Object.values(odds);
  }
  return [];
};

const toggleOddSelection = (matchId, marketId, oddId, odd, marketName) => {
  console.log("Odd selected:", odd);
  // Check if there's an existing selection for this market
  const existingSelection = store.state.betslip.selections.find(
    (selection) =>
      selection.matchId === matchId && selection.marketId === marketId
  );

  if (existingSelection) {
    // If the existing selection is different from the current one, remove it
    if (existingSelection.oddId !== oddId) {
      store.dispatch("betslip/addOrRemoveSelection", { matchId, marketId });
    } else {
      // If it's the same odd, just toggle its selection
      store.dispatch("betslip/addOrRemoveSelection", { matchId, marketId });
      updateSelectedOddsFromBetslip();
      return;
    }
  }

  // Add the new selection
  store.dispatch("betslip/addOrRemoveSelection", {
    matchId,
    marketId,
    oddId,
    matchName: `${match.value.homeTeamName} vs ${match.value.awayTeamName}`,
    market: marketName,
    odds: odd.odds,
  });

  // Update local state
  updateSelectedOddsFromBetslip();

  // Toggle betslip visibility
  const hasSelections = store.getters["betslip/selectionCount"] > 0;
  store.dispatch("betslip/toggleVisibility", hasSelections);
};

const getLayoutComponent = (layout) => {
  const props = {
    market: null,
    odds: null,
    toggleOddSelection,
    selectedOdds,
    matchId: match.value?.id,
  };
  switch (layout) {
    case 1:
      return h(GridLayout, props);
    case 2:
      return h(TableLayout, props);
    case 3:
      return h(OverUnderLayout, props);
    case 4:
      return h(PlayerLayout, props);
    case 5:
      return h(CorrectScoreLayout, props);
    default:
      return h(GridLayout, props);
  }
};

const GridLayout = {
  props: ["market", "odds", "toggleOddSelection", "selectedOdds", "matchId"],
  render() {
    return h(
      "div",
      {
        class: "grid gap-0.5",
        style: {
          gridTemplateColumns:
            "repeat(auto-fit, minmax(calc(33.333% - 2px), 1fr))",
        },
      },
      this.odds.map((odd) =>
        h(
          "div",
          {
            key: odd.id,
            class: `bg-custom-bg-3 py-3 px-3 flex justify-between items-center cursor-pointer ${
              this.selectedOdds[this.matchId]?.[this.market.id] === odd.id
                ? "bg-white"
                : ""
            }`,
            onClick: () =>
              this.toggleOddSelection(
                this.matchId,
                this.market.id,
                odd.id,
                odd,
                this.market.name
              ),
          },
          [
            h(
              "span",
              { class: "text-xs text-custom-gray" },
              odd.name || odd.header
            ),
            h(
              "span",
              {
                class: `font-bold text-sm ${
                  this.selectedOdds[this.matchId]?.[this.market.id] === odd.id
                    ? "text-custom-green"
                    : "text-custom-yellow"
                }`,
              },
              odd.odds.toFixed(2)
            ),
          ]
        )
      )
    );
  },
};

const TableLayout = {
  props: ["market", "odds", "toggleOddSelection", "selectedOdds", "matchId"],
  render() {
    return h(
      "div",
      {
        class: "grid gap-0.5",
        style: {
          gridTemplateColumns: "repeat(3, 1fr)",
        },
      },
      this.odds.map((odd) =>
        h(
          "div",
          {
            key: odd.id,
            class: `bg-custom-bg-3 p-2 flex flex-col items-center justify-center cursor-pointer ${
              this.selectedOdds[this.matchId]?.[this.market.id] === odd.id
                ? "bg-white"
                : ""
            }`,
            onClick: () =>
              this.toggleOddSelection(
                this.matchId,
                this.market.id,
                odd.id,
                odd,
                this.market.name
              ),
          },
          [
            h(
              "span",
              { class: "text-custom-gray text-xs text-center mb-1" },
              odd.name
            ),
            h(
              "span",
              {
                class: `font-bold text-sm ${
                  this.selectedOdds[this.matchId]?.[this.market.id] === odd.id
                    ? "text-custom-green"
                    : "text-custom-yellow"
                }`,
              },
              odd.odds.toFixed(2)
            ),
          ]
        )
      )
    );
  },
};

const OverUnderLayout = {
  props: ["market", "odds", "toggleOddSelection", "selectedOdds", "matchId"],
  render() {
    return h("div", { class: "w-full bg-custom-bg-1" }, [
      h(
        "div",
        {
          class:
            "grid grid-cols-3 gap-[1px] bg-custom-bg-gray-2 text-center py-1 mb-[1px]",
        },
        [
          h("div", { class: "col-span-1" }),
          h("div", { class: "col-span-1 text-custom-black" }, "Over"),
          h("div", { class: "col-span-1 text-custom-black" }, "Under"),
        ]
      ),
      h(
        "div",
        { class: "grid grid-cols-3 gap-[1px]" },
        this.odds
          .filter((_, index) => index % 2 === 0)
          .flatMap((odd, index) => [
            h(
              "div",
              {
                key: `total-${index}`,
                class:
                  "bg-custom-bg-1 text-custom-gray py-3 text-center flex items-center justify-center",
              },
              odd.name
            ),
            h(
              "div",
              {
                key: `over-${index}`,
                class: `bg-custom-bg-3 py-3 text-center flex items-center justify-center cursor-pointer ${
                  this.selectedOdds[this.matchId]?.[this.market.id] ===
                  this.odds[index * 2].id
                    ? "bg-white"
                    : ""
                }`,
                onClick: () =>
                  this.toggleOddSelection(
                    this.matchId,
                    this.market.id,
                    this.odds[index * 2].id,
                    this.odds[index * 2],
                    this.market.name
                  ),
              },
              h(
                "span",
                {
                  class: `${
                    this.selectedOdds[this.matchId]?.[this.market.id] ===
                    this.odds[index * 2].id
                      ? "text-custom-green"
                      : "text-custom-yellow"
                  }`,
                },
                this.odds[index * 2].odds.toFixed(2)
              )
            ),
            h(
              "div",
              {
                key: `under-${index}`,
                class: `bg-custom-bg-3 py-3 text-center flex items-center justify-center cursor-pointer ${
                  this.selectedOdds[this.matchId]?.[this.market.id] ===
                  this.odds[index * 2 + 1].id
                    ? "bg-white"
                    : ""
                }`,
                onClick: () =>
                  this.toggleOddSelection(
                    this.matchId,
                    this.market.id,
                    this.odds[index * 2 + 1].id,
                    this.odds[index * 2 + 1],
                    this.market.name
                  ),
              },
              h(
                "span",
                {
                  class: `${
                    this.selectedOdds[this.matchId]?.[this.market.id] ===
                    this.odds[index * 2 + 1].id
                      ? "text-custom-green"
                      : "text-custom-yellow"
                  }`,
                },
                this.odds[index * 2 + 1].odds.toFixed(2)
              )
            ),
          ])
      ),
    ]);
  },
};

const PlayerLayout = {
  props: ["market", "odds", "toggleOddSelection", "selectedOdds", "matchId"],
  render() {
    return h(
      "div",
      { class: "grid grid-cols-1 gap-0.5" },
      this.odds.map((odd) =>
        h(
          "div",
          {
            key: odd.id,
            class: `bg-custom-bg-3 py-3 px-3 flex justify-between items-center cursor-pointer ${
              this.selectedOdds[this.matchId]?.[this.market.id] === odd.id
                ? "bg-white"
                : ""
            }`,
            onClick: () =>
              this.toggleOddSelection(
                this.matchId,
                this.market.id,
                odd.id,
                odd,
                this.market.name
              ),
          },
          [
            h("span", { class: "text-xs" }, `${odd.name} (${odd.header})`),
            h(
              "span",
              {
                class: `font-bold text-sm ${
                  this.selectedOdds[this.matchId]?.[this.market.id] === odd.id
                    ? "text-custom-green"
                    : "text-custom-yellow"
                }`,
              },
              odd.odds.toFixed(2)
            ),
          ]
        )
      )
    );
  },
};

const CorrectScoreLayout = {
  props: ["market", "odds", "toggleOddSelection", "selectedOdds", "matchId"],
  setup(props) {
    const flattenedOdds = computed(() => {
      return props.odds.map((odd) => ({
        id: odd.id,
        score: odd.name,
        odds: odd.odds.toFixed(2),
      }));
    });

    return { flattenedOdds };
  },
  render() {
    return h(
      "div",
      {
        class: "grid bg-custom-bg-4",
        style: {
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "1px",
        },
      },
      this.flattenedOdds.map((odd) =>
        h(
          "div",
          {
            key: odd.score,
            class: `bg-custom-bg-3 flex flex-col items-center justify-center cursor-pointer ${
              this.selectedOdds[this.matchId]?.[this.market.id] === odd.id
                ? "bg-white"
                : ""
            }`,
            style: {
              padding: "4px",
            },
            onClick: () =>
              this.toggleOddSelection(
                this.matchId,
                this.market.id,
                odd.id,
                odd,
                this.market.name
              ),
          },
          [
            h("span", { class: "text-custom-gray text-xs" }, odd.score),
            h(
              "span",
              {
                class: `text-sm font-bold ${
                  this.selectedOdds[this.matchId]?.[this.market.id] === odd.id
                    ? "text-custom-green"
                    : "text-custom-yellow"
                }`,
              },
              odd.odds
            ),
          ]
        )
      )
    );
  },
};
</script>