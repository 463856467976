<template>
  <div class="min-h-screen bg-custom-black">
    <template v-if="isLoading">
      <div class="p-4 max-w-7xl mx-auto">
        <SimpleSpinner />
      </div>
    </template>
    <template v-else>
      <MainLayout :sports="sports">
        <div class="bg-custom-black min-h-screen">
          <!-- Header -->
          <div class="p-0">
            <div class="flex justify-between items-center mb-6">
              <div class="flex items-between">
                <button @click="goBack" class="text-gray-400 mr-2">
                  <ChevronLeftIcon class="h-6 w-6" />
                </button>
                <h2 class="text-xl font-semibold text-white">
                  {{ t("withdrawalRequests.title") }}
                </h2>
              </div>
            </div>

            <!-- Date Range Picker -->
            <div class="flex flex-col items-center gap-4 mb-6 max-w-3xl mx-auto">
              <div class="flex justify-center gap-2 w-full">
                <input
                  type="date"
                  v-model="dateRange.start"
                  class="w-full md:w-1/3 bg-custom-black-2 px-4 py-2 text-gray-300 border border-gray-700"
                />
                <input
                  type="date"
                  v-model="dateRange.end"
                  class="w-full md:w-1/3 bg-custom-black-2 px-4 py-2 text-gray-300 border border-gray-700"
                />
                <button
                  @click="applyDateFilter"
                  class="bg-custom-bg-green text-white px-6 py-2 hover:bg-custom-green transition-colors"
                >
                  {{ t("withdrawalRequests.filter") }}
                </button>
              </div>
              <div class="flex justify-center gap-2 w-full">
                <button
                  @click="filterToday"
                  :class="[
                    'text-white px-4 py-2 transition-colors',
                    activeDateFilter === 'today' ? 'bg-custom-green' : 'bg-custom-bg-green hover:bg-custom-green'
                  ]"
                >
                  {{ t("withdrawalRequests.today") }}
                </button>
                <button
                  @click="filterYesterday"
                  :class="[
                    'text-white px-4 py-2 transition-colors',
                    activeDateFilter === 'yesterday' ? 'bg-custom-green' : 'bg-custom-bg-green hover:bg-custom-green'
                  ]"
                >
                  {{ t("withdrawalRequests.yesterday") }}
                </button>
                <button
                  @click="filterCurrentMonth"
                  :class="[
                    'text-white px-4 py-2 transition-colors',
                    activeDateFilter === 'currentMonth' ? 'bg-custom-green' : 'bg-custom-bg-green hover:bg-custom-green'
                  ]"
                >
                  {{ getCurrentMonthName() }}
                </button>
              </div>
            </div>

            <!-- Withdrawals List -->
            <div class="w-full">
              <div v-if="filteredWithdrawals.length > 0">
                <div
                  v-for="withdrawal in filteredWithdrawals"
                  :key="withdrawal.id"
                  class="border-b border-white/50"
                >
                  <div
                    class="flex items-center justify-between py-3 px-4 bg-custom-bg-4 cursor-pointer hover:bg-opacity-90"
                    @click="toggleWithdrawal(withdrawal)"
                  >
                    <div class="flex items-center space-x-1">
                      <div
                        :class="[
                          'w-4 h-4 rounded-full',
                          {
                            'bg-custom-green': withdrawal.status === 1,
                            'bg-red-500': withdrawal.status === 2,
                            'bg-gray-300': withdrawal.status === 0,
                          },
                        ]"
                      ></div>
                      <span class="text-white text-sm">{{ withdrawal.user?.username || 'Unknown User' }}</span>
                      <span class="text-gray-400 text-xs font-thin">
                        {{ formatTime(withdrawal.created_at) }}
                      </span>
                    </div>
                    <div class="flex items-center space-x-3">
                      <span
                        :class="[
                          'text-sm font-medium',
                          getWithdrawalStatusColor(withdrawal.status),
                        ]"
                      >
                        {{ t(`withdrawalRequests.status.${getStatusText(withdrawal.status)}`) }}
                      </span>
                      <span class="text-white text-sm">
                        {{ formatNumber(withdrawal.amount) }} {{ withdrawal.wallet?.currency?.code || 'Unknown Currency' }}
                      </span>
                      <ChevronDownIcon
                        :class="[
                          'h-5 w-5 text-gray-400 transition-transform',
                          withdrawal.showDetails ? 'transform rotate-180' : '',
                        ]"
                      />
                    </div>
                  </div>

                  <!-- Withdrawal Details -->
                  <div v-show="withdrawal.showDetails" class="bg-custom-bg-4 py-2 px-4">
                    <div class="flex justify-between mt-4">
                      <div class="flex flex-col">
                        <span class="text-gray-400 text-xs">{{ t("withdrawalRequests.txid") }}</span>
                        <span class="text-white text-sm">{{ withdrawal.txid || 'N/A' }}</span>
                      </div>
                      <div class="flex flex-col items-end">
                        <span class="text-gray-400 text-xs">{{ t("withdrawalRequests.walletId") }}</span>
                        <span class="text-white text-sm">{{ withdrawal.wallet_id || 'N/A' }}</span>
                      </div>
                    </div>
                    <div class="flex justify-between mt-2">
                      <div class="flex flex-col">
                        <span class="text-gray-400 text-xs">{{ t("withdrawalRequests.userId") }}</span>
                        <span class="text-white text-sm">{{ withdrawal.user_id || 'N/A' }}</span>
                      </div>
                      <div class="flex flex-col items-end">
                        <span class="text-gray-400 text-xs">{{ t("withdrawalRequests.username") }}</span>
                        <span class="text-white text-sm">{{ withdrawal.user?.username || 'N/A' }}</span>
                      </div>
                    </div>
                    <!-- Accept/Reject Buttons -->
                    <div class="flex justify-end mt-4 space-x-2">
                      <button
                        @click.stop="acceptWithdrawal(withdrawal.id)"
                        :disabled="withdrawal.status !== 0"
                        class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        {{ t("withdrawalRequests.accept") }}
                      </button>
                      <button
                        @click.stop="rejectWithdrawal(withdrawal.id)"
                        :disabled="withdrawal.status !== 0"
                        class="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 disabled:opacity-50 disabled:cursor-not-allowed"
                      >
                        {{ t("withdrawalRequests.reject") }}
                      </button>
                    </div>
                  </div>
                </div>

                <!-- Summary Row -->
                <div
                  class="flex justify-between items-center py-3 px-4 bg-[#2A2A3C] border-t border-gray-700/30"
                >
                  <span class="text-gray-300 text-sm">{{ t("withdrawalRequests.total") }}</span>
                  <span class="text-gray-300 text-sm">
                    {{ formatNumber(getTotalWithdrawals()) }} {{ getMainCurrency() }}
                  </span>
                </div>
              </div>
              <div v-else class="text-center py-4 text-gray-400">
                {{ t("withdrawalRequests.noWithdrawals") }}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ChevronLeftIcon, ChevronDownIcon } from "lucide-vue-next";
import MainLayout from "../layouts/MainLayout.vue";
import SimpleSpinner from "../components/loaders/SimpleSpinner.vue";
import axios from "axios";

const store = useStore();
const { t } = useI18n({
  messages: {
    en: {
      withdrawalRequests: {
        title: "Withdrawal Requests",
        filter: "Filter",
        today: "Today",
        yesterday: "Yesterday",
        txid: "Transaction ID",
        walletId: "Wallet ID",
        userId: "User ID",
        username: "Username",
        total: "Total",
        noWithdrawals: "No withdrawal requests found for the selected period.",
        status: {
          pending: "Pending",
          approved: "Approved",
          rejected: "Rejected",
        },
        accept: "Accept",
        reject: "Reject",
      },
    },
  },
});

const isLoading = ref(false);
const sports = computed(() => store.state.sports || []);
const withdrawals = ref([]);
const dateRange = ref({
  start: new Date().toISOString().split("T")[0],
  end: new Date().toISOString().split("T")[0],
});
const activeDateFilter = ref('today');

const filteredWithdrawals = computed(() => {
  console.log("Computing filteredWithdrawals");
  console.log("Current withdrawals:", withdrawals.value);
  console.log("Current date range:", dateRange.value);

  if (!withdrawals.value || withdrawals.value.length === 0) {
    console.log("No withdrawals data available");
    return [];
  }

  return withdrawals.value.filter((withdrawal) => {
    if (!withdrawal.created_at) {
      console.warn("Withdrawal missing created_at:", withdrawal);
      return false;
    }
    const withdrawalDate = new Date(withdrawal.created_at);
    const startDate = new Date(dateRange.value.start);
    const endDate = new Date(dateRange.value.end);
    endDate.setHours(23, 59, 59, 999); 

    const isWithinRange = withdrawalDate >= startDate && withdrawalDate <= endDate;
    console.log(`Withdrawal ${withdrawal.id} date: ${withdrawalDate}, within range: ${isWithinRange}`);
    return isWithinRange;
  });
});

const setActiveDateFilter = (filter) => {
  activeDateFilter.value = filter;
};

const getAuthToken = () => {
  const authData = JSON.parse(localStorage.getItem("forbet_auth") || "{}");
  return authData.accessToken;
};

const toggleWithdrawal = (withdrawal) => {
  withdrawal.showDetails = !withdrawal.showDetails;
};

const fetchWithdrawals = async (startDate, endDate) => {
  const token = getAuthToken();
  if (!token) {
    console.error("No auth token found");
    return;
  }

  isLoading.value = true;
  try {
    const response = await axios.get(
      "https://api.newbet365.org/api/fiat-withdrawals",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }
    );
    console.log("Full API Response:", response);
    if (response.data && Array.isArray(response.data.data)) {
      withdrawals.value = response.data.data.map(withdrawal => ({
        ...withdrawal,
        showDetails: false
      }));
      console.log("Processed withdrawals:", withdrawals.value);
    } else {
      console.error("Unexpected API response structure:", response.data);
      withdrawals.value = [];
    }
  } catch (error) {
    console.error("Failed to fetch withdrawals:", error);
    if (error.response) {
      console.error("Error response:", error.response.data);
    }
    withdrawals.value = [];
  } finally {
    isLoading.value = false;
  }
};

const acceptWithdrawal = async (withdrawalId) => {
  const token = getAuthToken();
  if (!token) {
    console.error("No auth token found");
    return;
  }

  try {
    const response = await axios.post(
      `https://api.newbet365.org/api/fiat-withdrawals/${withdrawalId}/accept`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Accept withdrawal response:", response);
    // Update the local state
    const index = withdrawals.value.findIndex(w => w.id === withdrawalId);
    if (index !== -1) {
      withdrawals.value[index].status = 1; // Assuming 1 is the status for approved
    }
  } catch (error) {
    console.error("Failed to accept withdrawal:", error);
  }
};

const rejectWithdrawal = async (withdrawalId) => {
  const token = getAuthToken();
  if (!token) {
    console.error("No auth token found");
    return;
  }

  try {
    const response = await axios.post(
      `https://api.newbet365.org/api/fiat-withdrawals/${withdrawalId}/reject`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("Reject withdrawal response:", response);
    // Update the local state
    const index = withdrawals.value.findIndex(w=> w.id === withdrawalId);
    if (index !== -1) {
      withdrawals.value[index].status = 2; // Assuming 2 is the status for rejected
    }
  } catch (error) {
    console.error("Failed to reject withdrawal:", error);
  }
};

const goBack = () => {
  window.history.back();
};

const applyDateFilter = () => {
  console.log("Applying date filter:", dateRange.value);
  fetchWithdrawals(dateRange.value.start, dateRange.value.end);
};

const filterToday = async () => {
  const today = new Date().toISOString().split("T")[0];
  dateRange.value = { start: today, end: today };
  setActiveDateFilter('today');
  await fetchWithdrawals(today, today);
};

const filterYesterday = async () => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayString = yesterday.toISOString().split("T")[0];
  dateRange.value = { start: yesterdayString, end: yesterdayString };
  setActiveDateFilter('yesterday');
  await fetchWithdrawals(yesterdayString, yesterdayString);
};

const filterCurrentMonth = async () => {
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1)
    .toISOString()
    .split("T")[0];
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)
    .toISOString()
    .split("T")[0];
  dateRange.value = { start: firstDayOfMonth, end: lastDayOfMonth };
  setActiveDateFilter('currentMonth');
  await fetchWithdrawals(firstDayOfMonth, lastDayOfMonth);
};

const getCurrentMonthName = () => {
  return new Date().toLocaleString("default", { month: "long" });
};

const formatNumber = (value) => {
  if (!value) return "0.00";
  return parseFloat(value).toFixed(2);
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${year}-${month}-${day} ${hours}:${minutes}`;
};

const getWithdrawalStatusColor = (status) => {
  switch (status) {
    case 1:
      return "text-green-500";
    case 2:
      return "text-red-500";
    default:
      return "text-yellow-500";
  }
};

const getStatusText = (status) => {
  switch (status) {
    case 0:
      return "pending";
    case 1:
      return "approved";
    case 2:
      return "rejected";
    default:
      return "unknown";
  }
};

const getTotalWithdrawals = () => {
  return filteredWithdrawals.value.reduce((total, withdrawal) => total + parseFloat(withdrawal.amount), 0);
};

const getMainCurrency = () => {
  const firstWithdrawal = filteredWithdrawals.value[0];
  return firstWithdrawal && firstWithdrawal.wallet && firstWithdrawal.wallet.currency
    ? firstWithdrawal.wallet.currency.code
    : 'Unknown Currency';
};

onMounted(async () => {
  try {
    await store.dispatch("fetchSports");
    console.log("Component mounted, fetching today's data");
    await filterToday();
    console.log("Initial data fetch complete");
  } catch (error) {
    console.error("Failed to fetch initial data:", error);
  }
});
</script>

<style scoped>
input[type="date"] {
  position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.relative {
  position: relative;
  z-index: 20;
}
</style>

