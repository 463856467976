import { createI18n } from 'vue-i18n';
import translations from './locales/translations';

export default createI18n({
  legacy: false,
  locale: 'en', // set default locale
  fallbackLocale: 'en',
  messages: translations,
});

