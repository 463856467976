<script setup>
import { ref, defineProps, computed, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import SideMenu from "../components/SideMenu.vue";
import SimpleSpinner from "../components/loaders/SimpleSpinner.vue";
import BetSlip from "../components/BetSlip.vue";
import LoginModal from "../components/auth/LoginComponent.vue";
import UserAccountPanel from "../components/UserAccountPanel.vue";

const store = useStore();
const router = useRouter();
const isLoading = computed(() => store.state.isLoading);
const isBetSlipVisible = computed(() => store.state.betslip.isVisible);
const currentLiveSportId = computed(() => store.state.currentLiveSportId);
const isAuthenticated = computed(() => store.getters.isAuthenticated);
const user = computed(() => store.getters.user);
console.log("user in main layout:", user.value);
const { t, locale } = useI18n();

const currentLanguage = ref(locale.value);

const changeLanguage = () => {
  locale.value = currentLanguage.value;
  store.dispatch("setCurrentLanguage", currentLanguage.value);
};

watch(locale, (newLocale) => {
  localStorage.setItem("language", newLocale);
});

if (typeof window !== "undefined") {
  const savedLanguage = localStorage.getItem("language");
  if (savedLanguage) {
    locale.value = savedLanguage;
    currentLanguage.value = savedLanguage;
  }
}

const isSideMenuOpen = ref(false);
const isLoginModalOpen = ref(false);
const isAccountPanelOpen = ref(false);

defineProps({
  sports: {
    type: Array,
    required: true,
  },
});

const toggleSideMenu = () => {
  if (!isLoading.value) {
    isSideMenuOpen.value = !isSideMenuOpen.value;
  }
};

const navigateToInPlay = () => {
  if (router.currentRoute.value.name !== 'inplay' || router.currentRoute.value.params.sportId !== currentLiveSportId.value) {
    router.push(`/inplay/${currentLiveSportId.value}`);
  }
};

watch(isLoading, (newLoadingState) => {
  if (newLoadingState) {
    isSideMenuOpen.value = false;
  }
});

const hideSideMenu = () => {
  isSideMenuOpen.value = false;
};

const openLoginModal = () => {
  isLoginModalOpen.value = true;
};

const closeLoginModal = () => {
  isLoginModalOpen.value = false;
};

const handleLoginSuccess = () => {
  closeLoginModal();
};

const logout = () => {
  store.dispatch("logout");
  isAccountPanelOpen.value = false;
};

const handleShowLoginModal = () => {
  openLoginModal();
};

const toggleAccountPanel = () => {
  isAccountPanelOpen.value = !isAccountPanelOpen.value;
};
</script>

<template>
  <div
    class="flex flex-col min-h-screen bg-body-bg text-sm"
    :class="{ 'overflow-hidden h-screen': isLoginModalOpen }"
  >
    <!-- Side Menu -->
    <div
      class="fixed inset-y-0 left-0 w-72 bg-custom-bg-2 text-custom-white transform transition-transform duration-300 ease-in-out overflow-hidden z-50"
      :class="
        isSideMenuOpen && !isLoading ? 'translate-x-0' : '-translate-x-full'
      "
    >
      <SideMenu
        :sports="sports"
        @hideSidemenu="hideSideMenu"
        :currentLiveSportId="currentLiveSportId"
      />
    </div>

    <div
      class="transition-all duration-300 ease-in-out flex flex-col min-h-screen"
      :class="[isSideMenuOpen && !isLoading ? 'ml-72' : 'ml-0', 'w-full']"
    >
<!-- Header -->
<nav class="bg-custom-bg-green text-white text-sm pb-3 pt-0 px-2 sticky top-0 z-40">
  <div class="container mx-auto flex justify-between items-center">
    <!-- Side Menu Toggle -->
    <div class="flex items-center space-x-4 cursor-pointer" @click="toggleSideMenu">
      <i :class="isSideMenuOpen && !isLoading ? 'ri-close-line' : 'ri-menu-line'" class="text-sm text-custom-green"></i>
      <div class="flex items-center">
        <span class="text-xs">{{ t("header.az") }}</span>
      </div>
    </div>
    
    <!-- In-Play and Logo -->
    <div class="flex items-center space-x-6 cursor-pointer" @click="navigateToInPlay">
      <a href="#" class="hover:text-yellow-300">{{ t("header.inPlay") }}</a>
      <a href="#" class="flex items-center border-b-2 border-yellow-300 pb-1">
        <img :src="'/icons/logonew.png'" :alt="t('header.logoAlt')" class="h-6" />
      </a>
    </div>
    
    <!-- User Profile Section -->
    <div v-if="isAuthenticated" class="flex items-center space-x-4">
      <router-link to="/my-bets" class="text-custom-yellow hover:text-custom-yellow-bright">
        {{ t("header.myBets") }}
      </router-link>
      
      <!-- Account Panel and Role/Balance Display -->
      <div class="relative">
        <button @click="toggleAccountPanel" class="text-custom-green hover:text-custom-yellow-bright transition-all duration-300 ease-in-out font-thin" :class="{ 'transform scale-125': isAccountPanelOpen }">
          <i class="ri-account-circle-line text-2xl text-thin"></i>
        </button>
        
        <!-- Role/Balance Text -->
        <span class="absolute -bottom-3 left-1/2 -translate-x-1/2 text-xxs whitespace-nowrap text-white" v-show="!isAccountPanelOpen">
          {{ user.role === 'user' ? user.wallets[0].balance : user.role }}
        </span>
      </div>
    </div>
    
    <!-- Login Button -->
    <button v-else @click="openLoginModal" class="flex items-center space-x-2 bg-custom-bg-green px-4 py-1 rounded hover:bg-custom-bg-green-dark">
      <span class="text-custom-yellow">{{ t("header.login") }}</span>
    </button>
  </div>
</nav>


      <!-- Main Content -->
      <main class="flex-grow w-full relative">
        <!-- Global loading spinner -->
        <div
          v-if="isLoading"
          class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
        >
          <SimpleSpinner />
        </div>
        <slot v-else></slot>
      </main>

      <!-- Footer -->
  <footer class="bg-custom-bg-gray text-white text-sm">
    <div class="container mx-auto px-4">
      <div class="py-2 text-center border-b border-custom-bg-3">
        <button class="hover:underline">{{ t('footer.backToTop') }}</button>
      </div>
      <div class="py-6 flex justify-between items-center">
        <div class="text-2xl font-bold">{{ t('footer.footerTitle') }}</div>
        <select 
          v-model="currentLanguage" 
          @change="changeLanguage" 
          class="bg-white text-black px-4 py-2 rounded"
        >
          <option value="en">English</option>
          <option value="sq">Shqip</option>
          <option value="pt">Português</option>
          <option value="mk">Македонски јазик</option>
          <option value="it">Italiano</option>
          <option value="de">Deutsch</option>
          <option value="el">Ελληνικά</option>
          <option value="fr">Français</option>
          <option value="es">Español</option>
        </select>
      </div>
      <div class="py-4 flex justify-center space-x-6">
        <a href="#" class="hover:underline">{{ t('footer.termsAndConditions') }}</a>
        <a href="#" class="hover:underline">{{ t('footer.responsibleGambling') }}</a>
        <a href="#" class="hover:underline">{{ t('footer.privacyPolicy') }}</a>
      </div>
      <div class="py-4 text-center text-xs text-custom-white">
        <p>{{ t('footer.cookiesPolicy') }}</p>
      </div>
      <div class="py-4 text-center text-xs">
        <p>{{ t('footer.responsibleGamblingMessage') }}</p>
      </div>
      <div class="py-4 text-center text-xs text-custom-white">
        <p>{{ t('footer.copyrightNotice') }}</p>
      </div>
    </div>
  </footer>
    </div>

    <!-- User Account Panel -->
    <UserAccountPanel
      v-if="isAuthenticated"
      :user="user"
      :isPanelOpen="isAccountPanelOpen"
      @logout="logout"
      @close="toggleAccountPanel"
    />

    <!-- BetSlip -->
    <BetSlip v-if="isBetSlipVisible" @showLoginModal="handleShowLoginModal" />

    <!-- Login Modal -->
    <LoginModal
      v-if="isLoginModalOpen"
      @close="closeLoginModal"
      @login-success="handleLoginSuccess"
    />
  </div>
</template>

<style scoped>
/* Add any additional custom styles here */
.transform {
  transform-origin: center;
}
</style>

