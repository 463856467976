<template>
  <div
    class="fixed top-[40px] right-0 w-[90vw] md:w-96 bg-gray-100 overflow-hidden transition-all duration-300 ease-in-out z-40"
    :style="{
      transform: isPanelOpen ? 'translateX(0)' : 'translateX(100%)',
      height: 'calc(100vh - 40px)',
      maxHeight: '60vh'
    }"
  >
    <!-- Fixed Header -->
    <div class="absolute top-0 left-0 right-0 bg-gray-100 z-10 border-b border-gray-300">
      <div class="flex justify-between items-center p-4">
        <h2 class="text-lg font-semibold text-gray-800">{{ t('userAccountPanel.account') }}</h2>
        <button @click="$emit('close')" class="text-gray-600 hover:text-gray-800">
          <XIcon class="h-5 w-5" />
        </button>
      </div>
    </div>

    <!-- Scrollable Content -->
    <div class="h-full overflow-y-auto pt-[60px]">
      <!-- Username Section -->
      <div class="border-b border-gray-300 px-4 py-0">
        <div class="text-sm text-gray-600">{{ t('AgentID') }}</div>
        <div class="text-lg">{{user.id || t('userAccountPanel.notAvailable') }}</div>
      </div>

      <!-- Dynamic Wallet Sections -->
      <div v-for="wallet in user.wallets" :key="wallet.id" class="border-b border-gray-300 px-4 py-0">
        <div class="text-sm text-gray-600">{{ user.username}}</div>
        <div class="flex justify-between items-center">
          <span class="text-lg">{{ wallet.balance }} <span class="text-xs">{{ wallet.currency.code }}</span></span>
          
        </div>
      </div>

      <!-- Navigation Links -->
      <div>
        <router-link
          v-for="item in filteredMenuItems"
          :key="item.label"
          :to="item.path"
          class="block w-full p-4 text-gray-700 border-b border-gray-300 hover:bg-gray-200 transition-colors duration-200"
        >
          {{ t(item.label) }}
        </router-link>
      </div>

      <!-- Logout Button -->
      <button 
        @click="handleLogout" 
        class="w-full text-left p-4 text-gray-700 border-b border-gray-300 hover:bg-gray-200 transition-colors duration-200"
      >
        {{ t('userAccountPanel.logout') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { XIcon } from 'lucide-vue-next'

const store = useStore()
const { t } = useI18n()
const router = useRouter()

 defineProps({
  isPanelOpen: {
    type: Boolean,
    default: false
  }
})



const emit = defineEmits(['close', 'logout'])

const user = computed(() => store.getters.user)

const roleHierarchy = ['senior', 'manager', 'agent', 'user']

const menuItems = [
  { label: 'userAccountPanel.messages', path: '/messages' },
  { label: 'userAccountPanel.usersList', path: '/users', minRole: 'agent' },
  { label: 'userAccountPanel.accountBalance', path: '/balance', minRole: 'user' },
  { label: 'userAccountPanel.createNewUser', path: '/create-user', minRole: 'agent' },
  { label: 'userAccountPanel.changePassword', path: '/change-password' },
  // { label: 'userAccountPanel.infoAndRegulations', path: '/info' },
  // { label: 'userAccountPanel.withdraw', path: '/withdraw', userOnly: true },
  // { label: 'userAccountPanel.withdrawals', path: '/withdrawals',  userOnly: true  },
  // { label: 'userAccountPanel.withdrawalRequests', path: '/withdrawal-Requests',  minRole: 'agent'  },
]

const filteredMenuItems = computed(() => {
  if (!user.value) return []
  const userRoleIndex = roleHierarchy.indexOf(user.value.role)
  return menuItems.filter(item => {
    if (item.userOnly) return user.value.role === 'user'
    if (item.minRole) {
      const minRoleIndex = roleHierarchy.indexOf(item.minRole)
      return userRoleIndex <= minRoleIndex
    }
    return true
  })
})

const handleLogout = () => {
  store.dispatch('logout')
  emit('logout')
  router.push('/login')
}
</script>

<style scoped>
.overflow-y-auto::-webkit-scrollbar {
  display: none;
}

.overflow-y-auto {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>